<div class="succeed" *ngIf="isSucceed">
  <div class="icon"><i class="icon-ok"></i></div>
  <div class="title">Команда создана</div>
  <a href="javascript:;" class="btn btn-link" [routerLink]="inviteUserRoute">Пригласить игроков</a>
  <a href="javascript:;" class="back-link" [routerLink]="teamProfileRoute">На главную</a>
</div>

<form class="create-team-form"
      [formGroup]="form"
      (submit)="save()"
      *ngIf="!isSucceed && form"
>
  <div class="team-logo">
    <label>
      <input type="file" (change)="resizeImage($event)" accept="image/*" multiple/>
      <div class="empty-photo" *ngIf="!(team && team.logo.path) && !form.get('logo').value"></div>
      <img [mtgImageReader]="form.get('logo').value" *ngIf="form.get('logo').value"/>
      <img [src]="team.logo.path" *ngIf="team && team.logo.path && !form.get('logo').value"/>
      <div class="over" *ngIf="team && team.logo.path || form.get('logo').value"><i class="icon-load"></i></div>
      <span class="icon-abs" *ngIf="team">
          <span class="icon icon-volleyball" *ngIf="team.sport.isVolleyball()"></span>
          <span class="icon icon-basketball" *ngIf="team.sport.isClassicBasketball()"></span>
          <span class="icon icon-3x3" *ngIf="team.sport.isStreetball()"></span>
          <span class="icon icon-hockey-puck" *ngIf="team.sport.isHockey()"></span>
          <span class="icon icon-football" *ngIf="team.sport.isClassicFootball()"></span>
          <span class="icon icon-football-mini" *ngIf="team.sport.isMiniFootball()"></span>
          <span class="icon icon-handball" *ngIf="team.sport.isHandball()"></span>
          <span class="icon icon-waterpolo" *ngIf="team.sport.isWaterpolo()"></span>
      </span>
    </label>
  </div>

  <h1 *ngIf="team">Ваша команда</h1>
  <h1 *ngIf="!team">Новая команда</h1>
  <div class="team-name">
    <mtg-input [label]="'Название команды'"
               [transparent]="true"
               formControlName="name"
    ></mtg-input>
  </div>
  <div class="team-city" *ngIf="form.get('sport').enabled">
    <mtg-select
      formControlName="sport"
      placeholder="Вид спорта"
      [transparent]="true"
    >
      <ng-container *ngFor="let rootSport of sports">
        <mtg-option-group
          *ngIf="!rootSport.isHidden"
          [title]="rootSport.name"
        >
          <ng-container *ngFor="let sport of rootSport.subSports">
            <mtg-option
              *ngIf="!sport.isHidden"
              [value]="sport"
            >{{ sport.name }}</mtg-option>
          </ng-container>
        </mtg-option-group>
      </ng-container>
    </mtg-select>
  </div>
  <div class="team-city">
    <mtg-select
      formControlName="country"
      placeholder="Страна"
      [type]="'transparent'"
    >
      <mtg-option
        *ngFor="let item of countries"
        [value]="item"
      >{{ countriesLocalization[item] }}</mtg-option>
    </mtg-select>
  </div>
  <div class="team-city" *ngIf="form.get('country').value">
    <mtg-select
      formControlName="city"
      placeholder="Город"
      [withSearch]="true"
      [type]="'transparent'"
    >
      <mtg-option
        *ngFor="let item of filterCities"
        [value]="item"
        [selected]="form.get('city').value && item.id === form.get('city').value['id']"
      >{{ item.name }}</mtg-option>
    </mtg-select>
  </div>
  <div class="privacy-settings">
    <div class="title">Настройка приватности</div>
    <ul class="b-settings-group" [formGroup]="settingsForm">
      <li>
        <div>
          <span>Все видят статистику команды</span>
          <mtg-switcher formControlName="statistic" [value]="settingsForm.get('statistic').value"></mtg-switcher>
        </div>
      </li>
      <li>
        <div>
          <span>Все видят игры команды</span>
          <mtg-switcher formControlName="games" [value]="settingsForm.get('games').value"></mtg-switcher>
        </div>
      </li>
      <li>
        <div>
          <span>Все видят события команды</span>
          <mtg-switcher formControlName="events" [value]="settingsForm.get('events').value"></mtg-switcher>
        </div>
      </li>
      <li>
        <div>
          <span>Все видят опросы команды</span>
          <mtg-switcher formControlName="polls" [value]="settingsForm.get('polls').value"></mtg-switcher>
        </div>
      </li>
    </ul>
  </div>
  <button type="submit" mtgButton="primary">{{ team ? 'Сохранить' : 'Создать'}}</button>
  <button
    type="button"
    mtgButton="default"
    mtgButtonType="link"
    (click)="deleteTeam()"
    *ngIf="team && !isSucceed"
  >Удалить команду</button>
</form>
