import { ModelInstance, ToBackHook } from '@core/models/util';

@ModelInstance({
  mappingFields: {
    email: 'email',
    first_name: 'firstName',
    last_name: 'lastName',
    company_name: 'companyName',
    phone: 'phone',
    new_password: 'newPassword',
    city: 'city'
  }
})
export class RegistrationModel {
  @ToBackHook
  static toBack(modelData: any): any {
    return null;
  }
}
