import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Notification } from '@core/models/notification';
import { Router } from '@angular/router';
import { NotificationFilter } from '@shared/modules/notification/interfaces/notification-filter';
import { NotificationItem } from '@shared/modules/notification/interfaces/notification-item';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'mtg-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [
    trigger('slideRight', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('0.3s ease', style({transform: 'translateX(0)'}))
      ]),
      transition(':leave', [
        style({transform: 'translateX(0)'}),
        animate('0.3s ease', style({transform: 'translateX(100%)'}))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements AfterViewInit, OnDestroy {
  private _subscriptions: Function[] = [];
  private _filterType: NotificationFilter;

  @Input()
  notifications: NotificationItem[] = [];
  @Input()
  loading: boolean;
  @Input()
  notificationSettingsRoute: string[];
  @Input()
  set filterType(value: NotificationFilter) {
    this.filterValue = this.filterItems.find(item => item.value === value);
    this._filterType = value;
  }
  get filterType(): NotificationFilter {
    return this._filterType;
  }
  @Output()
  close = new EventEmitter();
  @Output()
  nextPage = new EventEmitter();
  @Output()
  viewed = new EventEmitter<Notification>();
  @Output()
  filterChanged = new EventEmitter();
  @Output()
  markAllViewed = new EventEmitter();

  @HostBinding('@slideRight')
  get animate() {
    return true;
  }

  @ViewChild('notificationsContainer', {read: ElementRef})
  notificationsContainer: ElementRef;
  filterItems: {value: NotificationFilter, title: string}[] = [
    {
      title: 'Важные',
      value: 'important'
    },
    {
      title: 'Информационные',
      value: 'information'
    },
    {
      title: 'Все',
      value: 'all'
    }
  ];
  filterValue: {value: NotificationFilter, title: string};

  constructor(
    private renderer2: Renderer2,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this._subscriptions.push(
      this.renderer2.listen(this.notificationsContainer.nativeElement, 'scroll', event => {
        const scrollHeight = this.notificationsContainer.nativeElement.scrollHeight;
        const height = this.notificationsContainer.nativeElement.clientHeight;
        const scrollTop = this.notificationsContainer.nativeElement.scrollTop;
        if (scrollTop === (scrollHeight - height) && !this.loading) {
          this.nextPage.emit();
          event.preventDefault();
        }
      })
    );
    this._subscriptions.push(
      this.renderer2.listen(this.notificationsContainer.nativeElement, 'mousewheel', (e) => {
        const scrollHeight = this.notificationsContainer.nativeElement.scrollHeight;
        const height = this.notificationsContainer.nativeElement.clientHeight;
        const scrollTop = this.notificationsContainer.nativeElement.scrollTop;
        if (scrollTop === (scrollHeight - height) && e.deltaY > 0 || (scrollTop === 0 && e.deltaY < 0)) {
          e.preventDefault();
          e.stopPropagation();
        }
      })
    );
  }

  ngOnDestroy(): void {
  }

  changeFilter(item: any): void {
    if (item.value !== this.filterValue.value) {
      this.filterValue = item;
      this.filterChanged.emit(item.value);
    }
  }

  openSettings(): void {
    this.router.navigate(this.notificationSettingsRoute);
    this.close.emit();
  }

  onClick(event, item: NotificationItem): boolean {
    if (item.externalLink) {
      return;
    }
    event.preventDefault();

    if (!item.model.viewed && !item.model.protected) {
      this.viewed.emit(item.model);
    }
    if (item.route) {
      this.router.navigate(item.route);
      this.close.emit();
    }
    return false;
  }

  trackById(index: number, item: NotificationItem): number {
    return item.model.id;
  }
}
