import { Component, ElementRef, forwardRef, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { countFileSize } from '@shared/util/file';

@Component({
  selector: 'mtg-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true
    }
  ]
})
export class FileInputComponent implements ControlValueAccessor, OnInit {
  constructor(
    @Optional() private _parentFormGroup: FormGroupDirective,
  ) {}

  ngOnInit() {
    if (this._parentFormGroup) {
      this._control = this._parentFormGroup.control.get(this.formControlName);
    }
  }

  @ViewChild('input', {read: ElementRef})
  input: ElementRef;

  @Input() formControlName: string;
  @Input() disabled = false;

  private _value = [];
  private _control: AbstractControl;
  private _onChangeFunc: any;
  private _onTouchedFunc: any;

  writeValue(value: any): void {
    if (!value) {
      return;
    }

    this._value.push(value[0]);
    // this.input.nativeElement.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedFunc = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changeValue(value: any) {
    this._value.push(value[0]);
    this._onChangeFunc(this._value);
    this._onTouchedFunc();
  }

  get value() {
    return this._value;
  }

  getFileSize(fileSize: number): string {
    return countFileSize(fileSize);
  }

  removeFileFromInput(fileIndex: number) {
    this._value.splice(fileIndex, 1);
  }
}
