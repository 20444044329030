import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeModule } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileDropZoneDirective } from './components/file-uploader/file-drop-zone.directive';
import { InputComponent } from './components/input/input.component';
import { TextMaskDirective } from './components/input/text-mask.directive';
import { RadioComponent } from './components/radio/radio.component';
import { SelectComponent } from './components/select/select.component';
import { OptionGroupComponent } from './components/select/option-group.component';
import { OptionComponent } from './components/select/option.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { TypeaheadDirective } from './components/typeahead/typeahead.directive';
import { TypeaheadOptionComponent } from './components/typeahead/typeahead-option.component';
import { SuggestHighlightPipe } from './components/typeahead/suggest-highlight.pipe';
import { SharedModule } from '@shared/modules/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    TextFieldModule,
    SharedModule,
  ],
  declarations: [
    CheckboxComponent,
    DatepickerComponent,
    FileInputComponent,
    FileUploaderComponent,
    FileDropZoneDirective,
    InputComponent,
    TextMaskDirective,
    RadioComponent,
    SelectComponent,
    OptionGroupComponent,
    OptionComponent,
    SwitcherComponent,
    TextareaComponent,
    TypeaheadComponent,
    TypeaheadDirective,
    TypeaheadOptionComponent,
    SuggestHighlightPipe,
  ],
  exports: [
    CheckboxComponent,
    DatepickerComponent,
    FileInputComponent,
    FileUploaderComponent,
    FileDropZoneDirective,
    InputComponent,
    TextMaskDirective,
    RadioComponent,
    SelectComponent,
    OptionGroupComponent,
    OptionComponent,
    SwitcherComponent,
    TextareaComponent,
    TypeaheadComponent,
    TypeaheadDirective,
    TypeaheadOptionComponent,
    SuggestHighlightPipe,
  ],
  providers: [
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'ru'},
    {provide: LOCALE_ID, useValue: 'ru'},
  ]
})
export class FormModule {}
