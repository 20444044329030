import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Game } from '@core/models/game';
import { DialogService } from '@ui-kit/dialog/dialog.service';
import { TournamentPlayoffGameDialogComponent } from '@shared/modules/shared/components/tournament-playoff/tournament-playoff-bracket/tournament-playoff-game-dialog/tournament-playoff-game-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'mtg-tournament-playoff-game',
  templateUrl: './tournament-playoff-game.component.html',
  styleUrls: ['./tournament-playoff-game.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentPlayoffGameComponent implements OnChanges {
  @Input()
  games: Game[];
  @Input()
  gameNumber: number;
  @Input()
  gameIndex: number;
  @Input()
  gameRound: number;
  @Input()
  winnerTeam: string;
  @Input()
  winnerCompetitorTeam: string;
  @Input()
  teamsCount: number;
  @Input()
  gameRoute: string;
  @Input()
  canManage: boolean;
  @Input()
  openInNewWindow: boolean;
  @Input()
  isDoubleElimination = false;
  @Input()
  isLoserBracketGame = false;
  @Output()
  gameForm = new EventEmitter<{playoffNumber: string, gameId: number}>();
  isClosed: boolean;
  teamWinnerId: number;
  teamScore: number;
  competitorTeamScore: number;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.games && this.games.length > 0) {
      this.isClosed = this.games.length > 0 && this.games.filter(g => g.isClosed).length === this.games.length;
      const winnerByTeams = this.games.filter(item => item.isClosed).reduce((acc, item) => {
        if (!acc[item.teamWinnerId]) {
          acc[item.teamWinnerId] = 0;
        }
        if (item.playoffBullitsTeamWinnerId !== null) {
          if (!acc[item.playoffBullitsTeamWinnerId]) {
            acc[item.playoffBullitsTeamWinnerId] = 0;
          }
          acc[item.playoffBullitsTeamWinnerId]++;
        }
        acc[item.teamWinnerId]++;
        return acc;
      }, {});
      this.teamScore = winnerByTeams[this.firstGame.team.id] || 0;
      this.competitorTeamScore = winnerByTeams[this.firstGame.competitorTeam.id] || 0;
      if (this.isClosed) {
        if (this.teamScore !== this.competitorTeamScore) {
          this.teamWinnerId = this.teamScore > this.competitorTeamScore
            ? this.firstGame.team.id
            : this.firstGame.competitorTeam.id;
        } else {
          const scoreByTeams = this.games.filter(item => item.isClosed).reduce((acc, item) => ({
            ...acc,
            [item.team.id]: (acc[item.team.id] || 0) + item.teamScore,
            [item.competitorTeam.id]: (acc[item.competitorTeam.id] || 0) + item.competitorTeamScore
          }), {});
          this.teamWinnerId = scoreByTeams[this.firstGame.team.id] > scoreByTeams[this.firstGame.competitorTeam.id]
            ? this.firstGame.team.id
            : this.firstGame.competitorTeam.id;
        }
      }
    }
  }

  isFinal() {
    if (this.isLoserBracketGame) {
      return false;
    }
    if (this.isDoubleElimination) {
      return this.gameRound === Math.log(this.teamsCount) / Math.log(2);
    } else {
      return 1 + this.gameRound === Math.log(this.teamsCount) / Math.log(2);
    }
  }

  get firstGame() {
    return this.games[0];
  }

  get lastGame() {
    return this.games[this.games.length - 1];
  }

  get textGameNumber() {
    if (this.isLoserBracketGame) {
      return 'L' + this.gameNumber;
    } else {
      return this.gameNumber;
    }
  }

  openGameRoute(playoffId: number, gameId: number): void {
    if (!this.canManage && (!this.firstGame.team.id || !this.firstGame.competitorTeam.id)) {
      return;
    }
    if (this.games.length > 1) {
      this.router.navigate([playoffId.toString(), gameId.toString()], {
        relativeTo: this.route
      });
    } else {
      const gameUrl = this.gameRoute.replace(':gameId', this.firstGame.id.toString());
      if (this.openInNewWindow) {
        window.open(gameUrl, '_blank');
      } else {
        this.router.navigate([gameUrl.toString()]);
      }
    }
  }

  createGameForm(): void {
    this.gameForm.emit({
      playoffNumber: `${this.isLoserBracketGame ? 'l_' : ''}${this.gameRound + 1}_${this.gameIndex + 1}`,
      gameId: null
    });
  }
}
