import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'mtgDate'
})
export class DatePipe implements PipeTransform {
  transform(value: any, ...args): any {
    const days = moment().startOf('day').diff(moment(value).startOf('day'), 'days');
    if (days === 0) {
      return 'Сегодня, ' + value.format('HH:mm');
    }
    if (days === 1) {
      return 'Вчера, ' + value.format('HH:mm');
    }
    if (days === -1) {
      return 'Завтра, ' + value.format('HH:mm');
    }
    return value.format('DD.MM.YYYY, HH:mm');
  }
}
