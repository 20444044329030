import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, enumField } from './util';

export enum Country {
  RU = 'RU',
  BL = 'BL',
  AM = 'AM',
  KZ = 'KZ',
  KG = 'KG',
  USA = 'USA',
  GE = 'GE',
  AZ = 'AZ',
  CHN = 'CHN',
  EGY = 'EGY',
  IRN = 'IRN'
}

export const CountryLocalization = {
  [Country.RU]: 'Россия',
  [Country.BL]: 'Беларусь',
  [Country.AM]: 'Армения',
  [Country.KZ]: 'Казахстан',
  [Country.KG]: 'Кыргызстан',
  [Country.USA]: 'США',
  [Country.GE]: 'Грузия',
  [Country.AZ]: 'Азербайджан',
  [Country.CHN]: 'Китай',
  [Country.EGY]: 'Египет',
  [Country.IRN]: 'Иран',
};

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    country: 'country'
  },
  relation: {
    country: enumField(Country)
  }
})
export class City extends BaseModel {
  id: number;
  name: string;
  country: Country;

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
