<div class="poll-card" [routerLink]="getPollDetailRoute(poll.id)">
  <div class="poll-question">
    <div class="block-ellipsis">{{ poll.name }}</div>
  </div>
  <div class="poll-date">
    Создан {{ poll.createdAt | date: 'd MMM yyyy, HH:MM' }}
  </div>
  <div class="poll-empty" *ngIf="!poll.answers.length">
    Создано вариантов {{ poll.variants.length }}.<br />
    Еще никто не ответил.
  </div>
  <div class="poll-votes" *ngIf="poll.answers.length">
    <ng-container *ngFor="let item of poll.variantsWithAnswers.slice().reverse()">
      <div class="poll-vote-scale"
           [ngClass]="{'primary-color': item.percent === maxPercent}"
           [ngStyle]="{width: item.percent + '%'}"
           *ngIf="item.percent > 0"
           [mtgTooltip]="item.variant.name"
      ><span>{{ item.percent >= 9 ? (item.percent + '%') : '' }}</span></div>
    </ng-container>
  </div>
  <div class="poll-controls">
    <div class="poll-legend-item"
         *ngIf="poll.answers.length"
         [ngClass]="{draw: isDraw()}"
    >
      <i></i>
      <span *ngIf="isDraw()">Нет единого мнения...</span>
      <span *ngIf="!isDraw()">{{ poll.variantsWithAnswers[0].variant.name }}</span>
    </div>
    <div [routerLink]="getPollEditRoute(poll.id)"
         mtgStopPropagation
         class="btn btn-circle"
         *ngIf="access.canManagePolls && poll.status !== pollStatuses.archived"
    >
      <i class="icon-edit"></i>
    </div>
  </div>
</div>
