import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationDataService } from '@shared/modules/notification/services/notification-data.service';
import { handleError } from '@shared/util/errors';
import { ToastService } from '@ui-kit/toast/toast.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed, UntilDestroy } from '@shared/util/component-destroyed';

@UntilDestroy
@Component({
  selector: 'mtg-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBellComponent implements OnInit, OnDestroy {
  notificationCount: number;

  constructor(
    private notificationDataService: NotificationDataService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.notificationDataService.notificationsCount$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(count => {
        this.notificationCount = count;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
  }

  openNotifications(): void {
    this.notificationDataService.showNotifications()
      .subscribe(() => {}, error => {
        handleError(error);
        this.toastService.danger('Ошибка загрузки уведомлений');
      });
  }
}
