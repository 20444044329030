import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input, Output
} from '@angular/core';
import { Game, GameStatuses } from '@core/models/game';
import { Playoff } from '@core/models/playoff';

@Component({
  selector: 'mtg-tournament-playoff-bracket',
  templateUrl: './tournament-playoff-bracket.component.html',
  styleUrls: [
    './tournament-playoff-bracket.component.scss',
    '../shared/playoff-bracket.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentPlayoffBracketComponent {
  @Input()
  games: Game[];
  @Input()
  playoff: Playoff;
  @Input()
  gameRoute = '';
  @Input()
  canManage: boolean;
  @Input()
  openGameInNewWindow: boolean;
  @Output()
  gameForm = new EventEmitter<{playoffNumber: string, gameId: number}>();
  @Input()
  currentRound: any;

  get toursCount(): number {
    return Math.log(this.playoff.settings.teamsCount) / Math.log(2);
  }

  getMatchesCount(i: number): number {
    return this.playoff.settings.teamsCount / Math.pow(2, i + 1);
  }

  generateArray(length: number): any[] {
    return new Array(length);
  }

  getGames(tourNumber: number, gameNumber: number): Game[] {
    return this.games.filter(game => game.playoffNumber === `${tourNumber + 1}_${gameNumber + 1}`).sort((a, b) => a.id - b.id);
  }

  getGameNumber(tour: number, game: number): number {
    return 1 + game + this.playoff.settings.teamsCount - this.playoff.settings.teamsCount / Math.pow(2, tour);
  }

  getNextGameTeam(tour: number, game: number, team: number = 0): any {
    if (tour === 0) {
      return;
    }
    const matchesPrevRound = this.getMatchesCount(tour - 1);
    const gameNumber = this.getGameNumber(tour, game);
    let t = (gameNumber - matchesPrevRound) + game + team;
    if (tour + 1 === this.toursCount) {
      if (game === 1) {
        t = (gameNumber - 1 - matchesPrevRound) + team;
        return 'Проигравший ' + t;
      }
    }
    return 'Победитель ' + t;
  }

  getTitle(tour: number, game: number = 0): string {
    const matchesCount = this.getMatchesCount(tour);
    const winnerPlace = this.playoff.winnerPlace;
    let finalTitle = 'Финал';
    let thirdPlaceTitle = '3 место';
    if (winnerPlace > 1) {
      finalTitle = winnerPlace + ' место';
      thirdPlaceTitle = (winnerPlace + 2) + ' место';
    }
    if (matchesCount === 4) {
      return 'Четвертьфинал';
    } else if (matchesCount === 2) {
      return 'Полуфинал';
    } else if (matchesCount === 1) {
      if (game === 0) {
        return finalTitle;
      } else {
        return thirdPlaceTitle;
      }
    } else {
      return `1/${matchesCount} финала`;
    }
  }
}
