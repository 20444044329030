import { Injectable } from '@angular/core';
import { NotificationTemplateService as BaseNotificationTemplateService } from '@shared/modules/notification/services/notification-template.service';
import { NotificationTemplate } from '@shared/modules/notification/interfaces/notification-template';
import { Notification, NotificationType } from '@core/models/notification';
import { TeamUserRoleLocalization } from '@core/localization/team-user-role';
import { TeamUserRole } from '@core/models/team-user';
import { TeamEventTypes } from '@core/models/team-event-types';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as RoutingMap from '@app/routing-map';
import { TeamService } from '@core/services/team.service';
import { UserService } from '@core/services/user.service';
import { Router } from '@angular/router';
import { TeamEventService } from '@core/services/team-event.service';
import { NotificationService } from '@core/services/notification.service';

@Injectable()
export class NotificationTemplateService extends BaseNotificationTemplateService {
  protected data: {[key: number]: NotificationTemplate} = {
    [NotificationType.team_updated]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => 'name' in (item.data.diff || {})
        ? `Команда ${item.data.diff.name.old} поменяла название на ${item.data.diff.name.new}`
        : 'logo' in (item.data.diff || {})
          ? `Изменился логотип команды ${item.team.name}`
          : `Данные команды обновлены`,
      description: () => '',
      route: (item) => this.getTeamRoute(item)
    },
    [NotificationType.team_invited]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => 'Вас пригласили в команду',
      description: (item: Notification) => `${item.initiator.shortName} предложил вам присоединиться к команде ${item.team.name}`,
      actions: [
        {
          text: 'Отказаться',
          action: item => this.declineTeamInvite(item),
          buttonColor: 'default',
          buttonType: 'link',
          markViewed: true,
        },
        {
          text: 'Принять предложение',
          action: item => this.acceptTeamInvite(item),
          buttonColor: 'primary',
          buttonType: 'button',
          markViewed: true,
        },
      ],
      route: (item) => this.getTeamRoute(item)
    },
    [NotificationType.team_join_request]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Новая заявка на вступление в команду ${item.team.name}`,
      description: (item: Notification) => `${item.initiator.shortName} подал заявку на вступление в команду ${item.team.name}`,
      actions: [
        {
          text: 'Отклонить',
          action: item => this.declineTeamJoinRequest(item),
          buttonColor: 'default',
          buttonType: 'link',
          markViewed: true,
        },
        {
          text: 'Принять в команду',
          action: item => this.acceptTeamJoinRequest(item),
          buttonColor: 'primary',
          buttonType: 'button',
          markViewed: true,
        },
      ],
      route: (item) => this.getUserRoute(item)
    },
    [NotificationType.team_join_declined_by_team]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `К сожалению, ваша заявка на вступлению в команду ${item.team.name} была отклонена 🙁`,
      description: () => '',
      route: (item) => this.getTeamRoute(item)
    },
    [NotificationType.team_join_declined_by_user]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.teamInvite.user.shortName} отклонил предложение присоединиться к ${item.team.name}`,
      description: () => '',
      route: (item) => this.getUserRoute(item)
    },
    [NotificationType.team_join_canceled_by_team]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `К сожалению, команда ${item.team.name} отозвала своё предложение пополнить их ряды 🙁`,
      description: () => '',
      route: item => this.getTeamRoute(item)
    },
    [NotificationType.team_join_canceled_by_user]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.initiator.shortName} отозвал свою заявку на вступление в ${item.team.name}`,
      description: () => '',
      route: item => this.getUserRoute(item)
    },
    [NotificationType.team_joined_for_team]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `В ${item.team.name} пополнение!`,
      description: (item: Notification) => `Отличая новость! К команде ${item.team.name} присоединился новый игрок - ${item.teamUser.user.shortName}`,
      route: item => this.getUserRoute(item)
    },
    [NotificationType.team_joined_for_user]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Вы присоединились к команде ${item.team.name}`,
      description: (item: Notification) => `Поздравляем! Вы вступили в команду ${item.team.name}, и вместе у вас есть все шансы достичь новых высот! 🥳`,
      route: item => this.getTeamRoute(item)
    },
    [NotificationType.team_user_role_changed_for_team]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Роль ${item.teamUser.user.shortName} в команде ${item.team.name} изменена на ${TeamUserRoleLocalization[item.data.role]}`,
      description: (item: Notification) => '',
      route: item => this.getTeamRoute(item)
    },
    [NotificationType.team_user_role_changed_for_user]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => 'Изменилась ваша роль в команде',
      description: (item: Notification) => `${item.initiator.shortName} изменил вашу роль в ${item.team.name} на ${TeamUserRoleLocalization[item.data.role]}`,
      route: item => this.getTeamRoute(item)
    },
    [NotificationType.team_user_number_changed]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Ваш номер в команде ${item.team.name} изменён на ${item.data.number}`,
      description: () => '',
      route: item => this.getTeamRoute(item)
    },
    [NotificationType.team_user_deleted_for_team]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.teamUser.user.shortName} был исключён из команды ${item.team.name}`,
      description: (item: Notification) => '',
      route: item => this.getUserRoute(item)
    },
    [NotificationType.team_user_deleted_for_user]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Вас исключили из команды 🙁`,
      description: (item: Notification) => `К сожалению, команда ${item.team.name} решила расстаться с вами. Но рассматривайте это, как возможность позитивных перемен - мир полон перспектив 😉`,
      route: item => this.getTeamRoute(item)
    },
    [NotificationType.team_user_left]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.teamUser.user.shortName} покинул команду 🙁`,
      description: (item: Notification) => `К сожалению, ${item.teamUser.user.shortName} принял решения покинуть команду ${item.team.name}`,
      route: item => this.getUserRoute(item)
    },

    [NotificationType.game_updated]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `В матче против ${this.getGameCompetitorTeamName(item)} изменилось время/место`,
      description: (item: Notification) => `Матч пройдёт ${item.game.datetime && item.game.datetime.format('DD.MM')} в ${item.game.datetime && item.game.datetime.format('HH:mm')}, по адресу: ${item.game.location}`,
      route: item => this.getGameRoute(item)
    },
    [NotificationType.game_invited]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Вашей команде ${item.team.name} предстоит матч против ${this.getGameCompetitorTeamName(item)}`,
      description: (item: Notification) => `Матч пройдёт ${item.game.datetime && item.game.datetime.format('DD.MM')} в ${item.game.datetime && item.game.datetime.format('HH:mm')}, по адресу: ${item.game.location}`,
      actions: [
        {
          text: 'Пропущу игру',
          action: item => this.declineGameInvite(item),
          buttonColor: 'default',
          buttonType: 'link',
          markViewed: true,
        },
        {
          text: 'Приду на игру',
          action: item => this.acceptGameInvite(item),
          buttonColor: 'primary',
          buttonType: 'button',
          markViewed: true,
        },
      ],
      route: item => this.getGameRoute(item)
    },
    [NotificationType.game_invite_accepted]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.gameInvite.teamUser.user.shortName} придёт на игру против ${this.getGameCompetitorTeamName(item)}!`,
      description: () => '',
      route: item => this.getGameRoute(item)
    },
    [NotificationType.game_invite_declined]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.gameInvite.teamUser.user.shortName} не сможет прийти на игру против ${this.getGameCompetitorTeamName(item)}`,
      description: () => '',
      route: item => this.getGameRoute(item)
    },
    [NotificationType.game_remind]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Напоминаем, вам предстоит матч с командой ${this.getGameCompetitorTeamName(item)}`,
      description: (item: Notification) => `Матч пройдёт ${item.game.datetime && item.game.datetime.format('DD.MM')} в ${item.game.datetime && item.game.datetime.format('HH:mm')}, по адресу: ${item.game.location}`,
      route: item => this.getGameRoute(item)
    },
    [NotificationType.game_won]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Победа над ${this.getGameCompetitorTeamName(item)}!`,
      description: (item: Notification) => `Поздравляем с победой! Так держать! Не забудьте зайти в раздел статистики и посмотреть как ваша победа выглядит в цифрах 😉`,
      route: item => this.getGameRoute(item)
    },
    [NotificationType.game_losing]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Поражение от ${this.getGameCompetitorTeamName(item)}`,
      description: (item: Notification) => `Не расстраивайтесь, все проходят через это. Это лишь ещё один повод стать сильнее. Загляните в статистику, ключ к улучшениям может быть в этих цифрах.`,
      route: item => this.getGameRoute(item)
    },
    [NotificationType.game_tech_victory]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Техническая победа над ${this.getGameCompetitorTeamName(item)}!`,
      description: (item: Notification) => `Команда ${this.getGameCompetitorTeamName(item)} не сможет принять участия в матче с вами ${item.game.datetime && item.game.datetime.format('DD.MM')} в ${item.game.datetime && item.game.datetime.format('HH:mm')}`,
      route: item => this.getGameRoute(item)
    },
    [NotificationType.game_tech_defeat]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Вашей команде присудили техническое поражение в матче против команды ${this.getGameCompetitorTeamName(item)}.`,
      description: () => ``,
      route: item => this.getGameRoute(item)
    },
    [NotificationType.poll_created]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Новый опрос в ${item.team.name}`,
      description: () => 'Каждый голос важен и должен быть услышен!',
      route: item => this.getPollRoute(item)
    },
    [NotificationType.poll_closed]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Опрос в команде ${item.team.name} завершён и все голоса посчитаны. Не забудьте ознакомиться с результатами.`,
      description: () => '',
      route: item => this.getPollRoute(item)
    },
    [NotificationType.team_event_invited]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `У команды ${item.team.name} намечается ${item.teamEvent.eventType === TeamEventTypes.training ? 'тренировка' : 'событие ' + item.teamEvent.eventCustomName}`,
      description: (item: Notification) => `${item.teamEvent.eventType === TeamEventTypes.training ? 'Тренировка' : 'Событие ' + item.teamEvent.eventCustomName} пройдёт ${item.teamEvent.datetime.format('DD.MM')} в ${item.teamEvent.datetime.format('HH:mm')}, по адресу ${item.teamEvent.location}`,
      actions: [
        {
          text: 'Не пойду',
          action: item => this.declineTeamEventInvite(item),
          buttonColor: 'default',
          buttonType: 'link',
          markViewed: true,
        },
        {
          text: 'Пойду',
          action: item => this.acceptTeamEventInvite(item),
          buttonColor: 'primary',
          buttonType: 'button',
          markViewed: true,
        },
      ],
      route: item => this.getTeamEventRoute(item)
    },
    [NotificationType.team_event_accepted]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => item.teamEvent.eventType === TeamEventTypes.training
        ? `${item.teamUser.user.shortName} придёт на тренировку ${item.teamEvent.datetime.format('DD.MM в HH:mm')}`
        : `${item.teamUser.user.shortName} обещал присутствовать на запланированном событии "${item.teamEvent.eventCustomName}"`,
      description: () => '',
      route: item => this.getTeamEventRoute(item)
    },
    [NotificationType.team_event_declined]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => item.teamEvent.eventType === TeamEventTypes.training
        ? `${item.teamUser.user.shortName} пропустит тренировку ${item.teamEvent.datetime.format('DD.MM в HH:mm')}`
        : `${item.teamUser.user.shortName} не сможет присутствовать на запланированном событии "${item.teamEvent.eventType}"`,
      description: () => '',
      route: item => this.getTeamEventRoute(item)
    },
    [NotificationType.team_event_remind]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Напоминаем, у команды ${item.team.name} намечается ${item.teamEvent.eventType === TeamEventTypes.training ? 'тренировка' : 'событие ' + item.teamEvent.eventCustomName}`,
      description: (item: Notification) => `${item.teamEvent.eventType === TeamEventTypes.training ? 'Тренировка' : 'Событие ' + item.teamEvent.eventCustomName} пройдёт ${item.teamEvent.datetime.format('DD.MM')} в ${item.teamEvent.datetime.format('HH:mm')}, по адресу ${item.teamEvent.location}`,
      route: item => this.getTeamEventRoute(item)
    },
    [NotificationType.tournament_invited]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Команду ${item.team.name} пригласили на турнир ${item.tournament.name}`,
      description: () => `Чтобы принять участие в турнире, вам нужно подать заявку и дождаться её рассмотрения организаторами турнира`,
      actions: [
        {
          text: 'Отклонить приглашение',
          action: item => this.declineTournamentInvite(item),
          buttonColor: 'default',
          buttonType: 'link',
          markViewed: true,
        },
        {
          text: 'Подать заявку',
          action: item => this.acceptTournamentInvite(item),
          buttonColor: 'primary',
          buttonType: 'button',
        },
      ],
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_joined]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Поздравляем! Ваша команда вступила в турнир ${item.tournament.name}`,
      description: () => 'Обязательно следите за расписание турнира, изучайте соперника и побеждайте!',
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_join_declined]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `К сожалению, заявка на участие вашей команды в турнире ${item.tournament.name} была отклонена`,
      description: () => '',
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_join_canceled]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Ваша команда отозвала заявку на участие в турнире ${item.tournament.name}`,
      description: () => '',
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_user_invited]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.initiator.shortName} добавил вас в заявку на турнир ${item.tournament.name}`,
      description: () => 'Вы сможете принять участие в турнире после одобрения вашей заявки организаторами',
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_user_accepted_for_team]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.teamUser.user.shortName} был допущен до участия в турнире ${item.tournament.name}!`,
      description: (item: Notification) => '',
      route: item => this.getTournamentTeamApplicationRoute(item)
    },
    [NotificationType.tournament_user_accepted_for_user]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Поздравляем! Вы стали участником турнира ${item.tournament.name}!`,
      description: (item: Notification) => 'Обязательно следите за расписанием турнира, изучайте соперника и побеждайте!',
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_user_added]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Поздравляем! Вы стали участником турнира ${item.tournament.name}!`,
      description: (item: Notification) => 'Обязательно следите за расписанием турнира, изучайте соперника и побеждайте!',
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_user_declined_for_team]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `К сожалению, ${item.teamUser.user.shortName} не был допущен до участия в турнире ${item.tournament.name}`,
      description: () => '',
      route: item => this.getTournamentTeamApplicationRoute(item)
    },
    [NotificationType.tournament_user_declined_for_user]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Сожалеем, но вы не были допущены до участие в турнире ${item.tournament.name} 🙁`,
      description: () => '',
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_user_invite_deleted]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.initiator.shortName} исключил вас из заявки на турнир ${item.tournament.name}`,
      description: () => '',
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_user_deleted]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.initiator.shortName} исключил вас из заявки на турнир ${item.tournament.name}`,
      description: () => '',
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_user_disqualified_for_team]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `${item.teamUser.user.shortName} был отстранён от участия в матчах турнира ${item.tournament.name}`,
      description: (item: Notification) => `Организаторы приняли решение отстранить ${item.teamUser.user.shortName} от игр на ${item.data.matches_count ? `${item.data.matches_count} ${this.pluralize(item.data.matches_count, ['матч', 'матча', 'матчей'])}` : item.data.date_to ? `период с ${moment(item.data.date_from).format('DD.MM.YYYY')} до ${moment(item.data.date_to).format('DD.MM.YYYY')}` : `время проведения турнира`}`,
      route: item => this.getTournamentTeamApplicationRoute(item)
    },
    [NotificationType.tournament_user_disqualified_for_user]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Вы были отстранены от участия в матчах турнира ${item.tournament.name}`,
      description: (item: Notification) => `Организаторы турнира ${item.tournament.name} приняли решение отстранить вас от игр на ${item.data.matches_count ? `${item.data.matches_count} ${this.pluralize(item.data.matches_count, ['матч', 'матча', 'матчей'])}` : item.data.date_to ? `период с ${moment(item.data.date_from).format('DD.MM.YYYY')} до ${moment(item.data.date_to).format('DD.MM.YYYY')}` : `время проведения турнира`}`,
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_user_disqualification_updated_for_team]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Условия дисквалификации игрока ${item.teamUser.user.shortName} были изменены`,
      description: (item: Notification) => `Организаторы турнира ${item.tournament.name} изменили условия дисквалификации ${item.teamUser.user.shortName} на следующие: ${item.data.matches_count ? `${item.data.matches_count} ${this.pluralize(item.data.matches_count, ['матч', 'матча', 'матчей'])}` : item.data.date_to ? `период с ${moment(item.data.date_from).format('DD.MM.YYYY')} до ${moment(item.data.date_to).format('DD.MM.YYYY')}` : 'до окончания турнира'}`,
      route: item => this.getTournamentTeamApplicationRoute(item)
    },
    [NotificationType.tournament_user_disqualification_updated_for_user]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Условия дисквалификации были изменены`,
      description: (item: Notification) => `Организаторы турнира ${item.tournament.name} изменили условия вашей дисквалицикации на следующие: ${item.data.matches_count ? `${item.data.matches_count} ${this.pluralize(item.data.matches_count, ['матч', 'матча', 'матчей'])}` : item.data.date_to ? `период с ${moment(item.data.date_from).format('DD.MM.YYYY')} до ${moment(item.data.date_to).format('DD.MM.YYYY')}` : 'до окончания турнира'}`,
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.tournament_team_deleted]: {
      logo: (item: Notification) => this.getTeamLogo(item),
      emptyLogoClass: (item: Notification) => this.getEmptyLogoClass(item),
      title: (item: Notification) => `Ваша команда снялась с турнира ${item.tournament.name}`,
      description: () => '',
      externalLink: item => this.getTournamentRoute(item)
    },
    [NotificationType.league_news]: {
      logo: (item: Notification) => this.getLeagueLogo(item),
      emptyLogoClass: (item: Notification) => item.league.getEmptyLogoClass(),
      title: (item: Notification) => `Важная новость от организиторов лиги ${item.league.name}`,
      description: (item: Notification) => item.leagueNews.title,
      externalLink: item => this.getLeagueNewsLink(item),
      actions: [
        {
          text: 'Читать',
          action: item => this.openLeagueNews(item),
          buttonColor: 'primary',
          buttonType: 'button',
          markViewed: true,
        }
      ]
    }
  };

  constructor(
    private teamService: TeamService,
    private userService: UserService,
    private teamEventService: TeamEventService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    super();
  }

  acceptTeamInvite(item: Notification): Observable<any> {
    return this.userService.acceptInvite(item.teamInvite.id);
  }

  declineTeamInvite(item: Notification): Observable<any> {
    return this.userService.declineInvite(item.teamInvite.id);
  }

  acceptTeamJoinRequest(item: Notification): Observable<any> {
    return this.teamService.acceptInvite(item.teamInvite);
  }

  declineTeamJoinRequest(item: Notification): Observable<any> {
    return this.teamService.declineInvite(item.teamInvite);
  }

  acceptGameInvite(item: Notification): Observable<any> {
    return this.userService.acceptGameInvite(item.gameInvite.id);
  }

  declineGameInvite(item: Notification): Observable<any> {
    return this.userService.declineGameInvite(item.gameInvite.id);
  }

  acceptTeamEventInvite(item: Notification): Observable<any> {
    return this.teamEventService.acceptTeamEventInvite(item.teamEventInvite);
  }

  declineTeamEventInvite(item: Notification): Observable<any> {
    return this.teamEventService.declineTeamEventInvite(item.teamEventInvite);
  }

  acceptTournamentInvite(item: Notification): Observable<any> {
    return new Observable<any>(observer => {
      const link = [
      RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE,
      item.team.id.toString(),
      RoutingMap.ROUTE_TEAM_TOURNAMENT_INVITE,
      item.tournamentInvite.id.toString()
    ];
      this.router.navigate(link);
      observer.complete();
    });
  }

  declineTournamentInvite(item: Notification): Observable<any> {
    return this.teamService.declineTournamentInvite(item.tournamentInvite.id);
  }

  getTeamRoute(item: Notification): string[] {
    return [RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE, item.team.id.toString()];
  }

  getUserRoute(item: Notification): string[] {
    return [RoutingMap.ROUTE_PROFILE_ABSOLUTE, item.teamUser.id ? item.teamUser.user.id.toString() : item.teamInvite.user.id.toString()];
  }

  getGameRoute(item: Notification): string[] {
    return [
      RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE,
      item.team.id.toString(),
      RoutingMap.ROUTE_GAME_ROOT,
      item.game.id.toString()
    ];
  }

  getPollRoute(item: Notification): string[] {
    return [
      RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE,
      item.team.id.toString(),
      RoutingMap.ROUTE_TEAM_POLL_ROOT,
      item.poll.id.toString()
    ];
  }

  getTeamEventRoute(item: Notification): string[] {
    return [
      RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE,
      item.team.id.toString(),
      RoutingMap.ROUTE_TEAM_EVENT_ROOT,
      item.teamEvent.id.toString()
    ];
  }

  getTournamentRoute(item: Notification): string {
    return `https://${item.tournament.league.domain}/tournament/${item.tournament.alias}`;
  }

  getTournamentTeamApplicationRoute(item: Notification): string[] {
    if (!item.tournamentTeam.id) {
      return this.getTeamRoute(item);
    }
    return [
      RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE,
      item.team.id.toString(),
      RoutingMap.ROUTE_TEAM_TOURNAMENT_APPLICATION,
      item.tournamentTeam.id.toString()
    ];
  }

  getTeamLogo(item: Notification): string {
    return item.team.logo.path;
  }

  getEmptyLogoClass(item: Notification): string {
    return item.team.getEmptyLogoClass();
  }

  getLeagueLogo(item: Notification): string {
    return item.league.logo.path;
  }

  getLeagueNewsLink(item: Notification): string {
    return `https://${item.league.domain}/news/${item.leagueNews.id}`;
  }

  openLeagueNews(item: Notification): Observable<any> {
    window.open(this.getLeagueNewsLink(item), '_blank');
    return this.notificationService.markViewed(item.id, true);
  }

  getGameCompetitorTeamName(item: Notification): string {
    return item.team.id === item.game.team.id
      ? item.game.competitorTeam.name || item.game.competitorTeamName
      : item.game.team.name;
  }
}
