import { TeamUserRole } from '@core/models/team-user';

export const TeamUserRoleLocalization = {
  [TeamUserRole.moderator]: 'Модератор',
  [TeamUserRole.member]: 'Пользователь',
  [TeamUserRole.admin]: 'Владелец',
  [TeamUserRole.coach]: 'Тренер',
  [TeamUserRole.head_coach]: 'Главный тренер',
  [TeamUserRole.playing_coach]: 'Играющий тренер',
  [TeamUserRole.manager]: 'Менеджер',
  [TeamUserRole.head_manager]: 'Генеральный менеджер',
  [TeamUserRole.captain]: 'Капитан',
  [TeamUserRole.assistant]: 'Ассистент',
  [TeamUserRole.president]: 'Президент',
  [TeamUserRole.vice_president]: 'Вице-президент',
  [TeamUserRole.press_attache]: 'Пресс-атташе',
  [TeamUserRole.executive]: 'Администратор',
  [TeamUserRole.senior_coach]: 'Старший тренер',
  [TeamUserRole.assistant_coach]: 'Помощник тренера',
  [TeamUserRole.serviceman]: 'Сервисмен',
  [TeamUserRole.game_application_manager]: 'Менеджер по заявкам на игры',
};
