import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { Notification } from '@core/models/notification';
import { PaginationResponse } from '@core/services/pagination-response.interface';
import { NotificationSettings } from '@core/models/notification-settings';

export interface NotificationFilters {
  popup?: boolean;
  protected?: boolean;
  not_viewed?: boolean;
  important?: boolean;
}

@Injectable()
export class NotificationBaseService {
  protected apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {}

  getCount(): Observable<number> {
    return this.httpClient
      .get(`${this.apiUrl}/notification/count/`)
      .pipe(
        map(data => +data)
      );
  }

  getList(page: number, size: number, filters: NotificationFilters = {}): Observable<PaginationResponse<Notification>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    for (const key of Object.keys(filters)) {
      params = params.set(key, filters[key]);
    }

    return this.httpClient
      .get(`${this.apiUrl}/notification/`, {params: params, observe: 'response'})
      .pipe(
        map(response => {
          const data: Notification[] = Notification.toFront(response.body);
          return {
            count: +response.headers.get('x-page-count'),
            data: data
          };
        }),
      );
  }

  markViewed(notificationId: number, viewed: boolean): Observable<Notification> {
    return this.httpClient
      .post(`${this.apiUrl}/notification/${notificationId}/viewed/`, {viewed})
      .pipe(
        map(data => Notification.toFront(data))
      );
  }

  markAllViewed(): Observable<Notification[]> {
    return this.httpClient
      .post(`${this.apiUrl}/notification/viewed/`, {})
      .pipe(
        map(data => Notification.toFront(data))
      );
  }

  getNotificationSettings(): Observable<NotificationSettings> {
    return this.httpClient.get(`${this.apiUrl}/notification_settings/`).pipe(
      map(data => NotificationSettings.toFront(data))
    );
  }

  updateNotificationSettings(data: NotificationSettings): Observable<NotificationSettings> {
    return this.httpClient.post(`${this.apiUrl}/notification_settings/`, NotificationSettings.toBack(data)).pipe(
      map(result => NotificationSettings.toFront(result))
    );
  }
}
