import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import * as RoutingMap from '../../../routing-map';
import { takeUntil } from 'rxjs/operators';
import { User } from '@core/models/user';
import { LayoutService } from '@app/layout/services/layout.service';
import { componentDestroyed, UntilDestroy } from '@shared/util/component-destroyed';
import { handleError } from '@shared/util/errors';
import { DialogService } from '@ui-kit/dialog/dialog.service';
import { FeedbackComponent } from '@app/layout/components/feedback/feedback.component';
import { Team } from '@core/models/team';
import { MyTeamsComponent } from '@app/shared/components/my-teams/my-teams.component';

@UntilDestroy
@Component({
  selector: 'mtg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User;
  profileRoute = RoutingMap.ROUTE_PROFILE_ABSOLUTE;
  profileEditRoute = RoutingMap.ROUTE_PROFILE_FORM_ABSOLUTE;
  profileSettingsRoute = RoutingMap.ROUTE_PROFILE_FORM_SETTINGS_ABSOLUTE;
  createTeamRoute = RoutingMap.ROUTE_PROFILE_CREATE_TEAM_ABSOLUTE;
  teams: Team[] = [];
  selectedTeam: Team;

  constructor(
    private router: Router,
    private authService: AuthService,
    private layoutService: LayoutService,
    private dialogService: DialogService,
  ) {}

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.layoutService.user$.pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => this.user = user);
    this.layoutService.teams$.pipe(takeUntil(componentDestroyed(this)))
      .subscribe(teams => {
        this.teams = teams;
        this.getSelectedTeam();
      });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getSelectedTeam();
      }
    });
  }

  openTeamMenu() {
    this.dialogService.open(MyTeamsComponent, {
      isBackButtonVisible: true
    });
  }

  getSelectedTeam(): void {
    if (this.isTeamPageRoute) {
      const teamId = +this.router.url.substr(RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE.length + 1);
      this.selectedTeam = this.teams.find(team => team.id === teamId);
    } else {
      this.selectedTeam = null;
    }
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigateByUrl(RoutingMap.ROUTE_AUTHENTICATION_LOGIN_ABSOLUTE);
    }, error => {
      handleError(error);
    });
  }

  openFeedbackModal() {
    this.dialogService.open(FeedbackComponent, {
      isCloseButtonVisible: true,
    });
  }

  getTeamDetailRoute(teamId: number): string[] {
    return [RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE, teamId.toString()];
  }

  get isTeamPageRoute() {
    return this.router.url.substr(0, RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE.length) === RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE;
  }
}
