import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { handleError } from '@shared/util/errors';
import { ToastService } from '@ui-kit/toast/toast.service';
import { NotificationItem, NotificationItemAction } from '@shared/modules/notification/interfaces/notification-item';
import { NotificationDataService } from '@shared/modules/notification/services/notification-data.service';

@Component({
  selector: 'mtg-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemComponent {
  @Input()
  notification: NotificationItem;
  @Output()
  viewed = new EventEmitter();
  loading: NotificationItemAction;

  @HostBinding('class.new')
  get classNew(): boolean {
    return !this.notification.model.viewed;
  }

  constructor(
    private toastService: ToastService,
    private notificationDataService: NotificationDataService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  doAction(action: NotificationItemAction): void {
    if (this.loading) {
      return;
    }
    this.loading = action;
    action.action(this.notification.model)
      .pipe(finalize(() => this.loading = null))
      .subscribe(() => {
        if (action.markViewed) {
          this.notification.model.viewed = true;
          this.notificationDataService.markViewedProtected(this.notification.model);
        }
        this.cdr.markForCheck();
      }, error => {
        handleError(error);
        this.toastService.danger('Произошла ошибка');
      });
  }
}
