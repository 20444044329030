import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Game } from '@core/models/game';
import { Team } from '@core/models/team';
import { DialogConfig } from '@ui-kit/dialog/dialog-config';
import { Router } from '@angular/router';
import { DialogRef } from '@ui-kit/dialog/dialog-ref';

@Component({
  selector: 'mtg-tournament-playoff-game-dialog',
  templateUrl: './tournament-playoff-game-dialog.component.html',
  styleUrls: ['./tournament-playoff-game-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentPlayoffGameDialogComponent {
  @Input()
  games: Game[];
  @Input()
  team: Team;
  @Input()
  competitorTeam: Team;
  @Input()
  teamScore: number;
  @Input()
  competitorTeamScore: number;
  @Input()
  teamWinnerId: number;
  @Input()
  gameRoute: string;
  @Input()
  openInNewWindow: boolean;

  constructor(
    private dialogConfig: DialogConfig,
    private dialogRef: DialogRef,
    private router: Router,
  ) {}

  openGamePage(game: Game): void {
    if (!this.gameRoute) {
      return;
    }
    const gameUrl = this.gameRoute.replace(':gameId', game.id.toString());
    if (this.openInNewWindow) {
      window.open(gameUrl, '_blank');
    } else {
      this.router.navigateByUrl(gameUrl);
    }
  }
}
