import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DialogRef } from '@ui-kit/dialog/dialog-ref';
import { CroppieDirective } from '@shared/modules/shared/directives/croppie.directive';
import { DialogConfig } from '@ui-kit/dialog/dialog-config';

@Component({
  selector: 'mtg-image-save',
  templateUrl: './image-save.component.html',
  styleUrls: ['./image-save.component.scss']
})
export class ImageSaveComponent implements OnInit {
  public image: any;
  config: Croppie.CroppieOptions;

  @ViewChild('croppie')
  public croppie: CroppieDirective;

  @Input()
  file: any;
  resultSize: {width: number, height: number};

  constructor(
    private dialogRef: DialogRef<ImageSaveComponent>,
    private dialogConfig: DialogConfig,
  ) {}

  ngOnInit() {
    this.config = this.dialogConfig['data']['options'];
    this.resultSize = this.dialogConfig['data']['size'];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.image = e.target['result'];
    };
    reader.readAsDataURL(this.dialogConfig.data['file']);
  }

  save() {
    this.croppie.croppie.result({size: this.resultSize, type: 'blob', format: this.dialogConfig.data['format'] || 'jpeg'}).then((blob) => {
      this.dialogRef.close(blob);
    });
  }
}
