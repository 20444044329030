import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Optional, Renderer2
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OptionComponent } from './option.component';
import { isTouchDevice } from '@shared/util/device';
import { ERRORS_TRANSLATION } from '../input/input.component';
import { OptionGroupComponent } from '@ui-kit/form/components/select/option-group.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'mtg-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  private _onChangeFunc: any;
  private _onTouchedFunc: any;
  private _control: AbstractControl;
  private _subscriptions: Function[] = [];
  public isDisabled: boolean;
  public options: OptionComponent[] = [];
  optionGroups: OptionGroupComponent[] = [];
  isMobile = isTouchDevice();
  selectControl = new FormControl();

  @Input()
  fluid = true;

  @Input()
  class: string;

  @Input()
  required = false;

  @Input()
  placeholder: string;

  @Input()
  type: 'transparent' | 'normal' | 'text' = 'normal';

  @Input()
  noBorder: boolean;

  @Input()
  transparent: boolean;

  @Input()
  multi: boolean;

  @Input()
  size: 'sm' | 'md' = 'md';

  @Input()
  formControlName: string;

  @Input()
  formControl: AbstractControl;

  @Input()
  errors: any[];

  @Input()
  withSearch: boolean;

  @Input()
  readOnly: boolean;

  search: string;
  search$ = new BehaviorSubject<string>(null);

  opened = false;

  _value = null;
  formattedValue = null;

  get value(): any {
    if (this.multi && !this._value) {
      return [];
    }
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    if (this.multi) {
      if (this.value.length > 0) {
        this.formattedValue = `Выбрано: ${this.value.length}`;
      } else if (this.value.length > 0) {
        const option = this.options.find(op => op.value === this.value[0]);
        if (option) {
          this.formattedValue = option.viewValue;
        }
      } else {
        this.formattedValue = '';
      }
    } else {
      const option = this.options.find(op => op.value === value);
      if (option) {
        this.formattedValue = option.viewValue;
      } else {
        this.formattedValue = null;
      }
    }
  }

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    @Optional() private _parentFormGroup: FormGroupDirective,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (this._parentFormGroup) {
      this._control = this.formControl || this._parentFormGroup.control.get(this.formControlName);
    }
    this.selectControl.valueChanges.subscribe(value => {
      this.changeValue(value);
    });
    this._subscriptions.push(
      this.renderer.listen('document', 'click', $event => {
        if (!this.elementRef.nativeElement.contains($event.target) && this.opened) {
          this.opened = false;
          this.cdr.markForCheck();
        }
      })
    );
  }

  @HostBinding('class')
  get className(): string {
    const classList = [];
    classList.push(this.size);
    if (this.class) {
      classList.push(...this.class.split(' '));
    }
    if (this.fluid) {
      classList.push('fluid');
    }
    if (this.opened) {
      classList.push('opened');
    }
    if (this.formattedValue) {
      classList.push('filled');
    }
    if (this.type === 'transparent' || this.transparent) {
      classList.push('transparent');
    } else if (this.type === 'text' || this.noBorder) {
      classList.push('no-border');
    }
    if (this.control && this.control.dirty && !this.control.valid) {
      classList.push('error');
    } else if (this.errors) {
      classList.push('error');
    }
    if (this.withSearch) {
      classList.push('with-search');
    }
    if (this.isDisabled || this.readOnly) {
      classList.push('disabled');
    }
    return classList.join(' ');
  }

  get control(): AbstractControl {
    return this._control || this.formControl;
  }

  toggleOptions(): void {
    this.opened = !this.opened;
    if (!this.opened && this._onTouchedFunc) {
      this._onTouchedFunc();
    }
  }

  changeValue(value: any): void {
    this.searchValue('');
    this.search$.next('');
    if (this.multi) {
      const currentValue = this.value;
      if (currentValue.indexOf(value) > -1) {
        currentValue.splice(this._value.indexOf(value), 1);
      } else {
        currentValue.push(value);
      }
      this.value = currentValue;
    } else {
      this.value = value;
      this.opened = false;
    }
    if (this._onChangeFunc) {
      this._onChangeFunc(this.value);
    }
    this.cdr.markForCheck();
  }

  addOption(option: OptionComponent): void {
    this.options.push(option);
    if (!this.multi) {
      if (option.value === this._value || option.selected) {
        setTimeout(() => {
          this.formattedValue = option.viewValue;
          this.cdr.markForCheck();
        });
      }
    }
  }

  searchValue(value: string): void {
    this.search = value;
    this.search$.next(value);
  }

  addOptionGroup(component: OptionGroupComponent): void {
    this.optionGroups.push(component);
  }

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedFunc = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: any) {
    this.searchValue('');
    this.value = value;
    this.cdr.markForCheck();
  }

  getErrors() {
    let errors = {};
    if (this.control && this.control.dirty && this.control.errors) {
      errors = this.control.errors;
    } else if (this.errors) {
      errors = this.errors;
    }
    return Object.keys(errors)
      .filter(k => errors[k])
      .reduce((acc, value) => {
        let error = ERRORS_TRANSLATION[value];
        if (!error) {
          return acc;
        }
        if (typeof errors[value] === 'object') {
          for (const errorKey of Object.keys(errors[value])) {
            error = error.replace(`{${errorKey}}`, errors[value][errorKey]);
          }
        }
        acc.push(error);
        return acc;
      }, []);
  }
}
