import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DataSliderComponent } from './components/data-slider/data-slider.component';
import { DataSliderItemComponent } from './components/data-slider/data-slider-item.component';
import { DialogPageComponent } from './components/dialog-page/dialog-page.component';
import { ImageSaveComponent } from './components/image-save/image-save.component';
import { CroppieDirective } from './directives/croppie.directive';
import { FireEventDirective } from './directives/fire-event.directive';
import { FitToParentDirective } from './directives/fit-to-parent.directive';
import { ImageReaderDirective } from './directives/image-reader.directive';
import { StickyDirective } from './directives/sticky.directive';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { ToggleDirective } from './directives/toggle.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { LocalizationPipe } from './pipes/localization.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { PaginationScrollDirective } from './directives/pagination-scroll.directive';
import {
  TournamentPlayoffBracketComponent
} from './components/tournament-playoff/tournament-playoff-bracket/tournament-playoff-bracket.component';
import {
  TournamentPlayoffGameComponent
} from './components/tournament-playoff/tournament-playoff-bracket/tournament-playoff-game/tournament-playoff-game.component';
import {
  TournamentPlayoffGameDialogComponent
} from './components/tournament-playoff/tournament-playoff-bracket/tournament-playoff-game-dialog/tournament-playoff-game-dialog.component';
import { IsVisibleDirective } from './directives/is-visible.directive';
import { ConfettiDirective } from '@shared/modules/shared/directives/confetti.directive';
import { SafePipe } from '@shared/modules/shared/pipes/safe.pipe';
import { DatePipe } from '@shared/modules/shared/pipes/date.pipe';
import {
  TournamentPlayoffLoserBracketComponent
} from '@shared/modules/shared/components/tournament-playoff/tournament-playoff-loser-bracket/tournament-playoff-loser-bracket.component';
import {
  TournamentPlayoffWinnerBracketComponent
} from '@shared/modules/shared/components/tournament-playoff/tournament-playoff-winner-bracket/tournament-playoff-winner-bracket.component';
import { WhiteImageFilterDirective } from '@shared/modules/shared/directives/white-image-filter.directive';
import { MaskImageDirective } from '@shared/modules/shared/directives/mask-image.directive';
import { MaskTextDirective } from '@shared/modules/shared/directives/mask-text.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    // Components
    ConfirmDialogComponent,
    DataSliderComponent,
    DataSliderItemComponent,
    DialogPageComponent,
    ImageSaveComponent,
    TournamentPlayoffBracketComponent,
    TournamentPlayoffLoserBracketComponent,
    TournamentPlayoffWinnerBracketComponent,
    TournamentPlayoffGameComponent,
    TournamentPlayoffGameDialogComponent,

    // Directives
    CroppieDirective,
    FireEventDirective,
    FitToParentDirective,
    ImageReaderDirective,
    StickyDirective,
    StopPropagationDirective,
    ToggleDirective,
    PaginationScrollDirective,
    IsVisibleDirective,
    ConfettiDirective,
    WhiteImageFilterDirective,
    MaskImageDirective,
    MaskTextDirective,

    // Pipes
    FilterPipe,
    LocalizationPipe,
    PhonePipe,
    PluralizePipe,
    SafePipe,
    DatePipe
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ImageSaveComponent,
    TournamentPlayoffGameDialogComponent,
  ],
  exports: [
    // Components
    ConfirmDialogComponent,
    DataSliderComponent,
    DataSliderItemComponent,
    DialogPageComponent,
    ImageSaveComponent,
    TournamentPlayoffBracketComponent,
    TournamentPlayoffLoserBracketComponent,
    TournamentPlayoffWinnerBracketComponent,
    TournamentPlayoffGameComponent,
    TournamentPlayoffGameDialogComponent,

    // Directives
    CroppieDirective,
    FireEventDirective,
    FitToParentDirective,
    ImageReaderDirective,
    StickyDirective,
    StopPropagationDirective,
    ToggleDirective,
    PaginationScrollDirective,
    IsVisibleDirective,
    ConfettiDirective,
    WhiteImageFilterDirective,
    MaskImageDirective,

    // Pipes
    FilterPipe,
    LocalizationPipe,
    PhonePipe,
    PluralizePipe,
    SafePipe,
    DatePipe,
    MaskTextDirective,
  ]
})
export class SharedModule {}
