import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Notification } from '@core/models/notification';
import { animate, style, transition, trigger } from '@angular/animations';
import { DIRECTION_HORIZONTAL, Manager, Swipe } from 'hammerjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { handleError } from '@shared/util/errors';
import { ToastService } from '@ui-kit/toast/toast.service';
import { Router } from '@angular/router';
import { NotificationItem, NotificationItemAction } from '@shared/modules/notification/interfaces/notification-item';
import { NotificationDataService } from '@shared/modules/notification/services/notification-data.service';
import { componentDestroyed, UntilDestroy } from '@shared/util/component-destroyed';
import { BreakpointObserver } from '@angular/cdk/layout';

@UntilDestroy
@Component({
  selector: 'mtg-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
  animations: [
    trigger('scaleInOut', [
      transition(':enter', [
        style({opacity: 0, transform: 'scale(.9)'}),
        animate('0.3s 0.3s cubic-bezier(.62,1.54,.96,.96)', style({opacity: 1, transform: 'scale(1)'}))
      ]),
      transition(':leave', [
        style({opacity: 1, transform: 'scale(1)'}),
        animate('0.3s cubic-bezier(.62,1.54,.96,.96)', style({opacity: 0, transform: 'scale(.9)'}))
      ])
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPopupComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  notifications: NotificationItem[];
  @Input()
  visible: boolean;
  @Input()
  notificationSettingsRoute: string[];
  @Output()
  hide = new EventEmitter();
  current = 0;
  @ViewChild('wrapper', {read: ElementRef})
  wrapper: ElementRef;
  loadings: {[key: number]: NotificationItemAction} = {};
  isMobile: boolean;

  @HostBinding('class.hidden')
  get isHidden() {
    return !this.visible || this.notifications.length === 0;
  }

  // @HostBinding('class.many')
  // get classMany() {
  //   return this.notifications && this.notifications.length > 1;
  // }

  get notification(): Notification {
    return this.notifications && this.notifications.length > 0 && this.notifications[0].model;
  }

  constructor(
    private notificationDataService: NotificationDataService,
    private elementRef: ElementRef,
    private toastService: ToastService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 700px)'])
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(observer => {
        this.isMobile = observer.matches;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    const manager = new Manager(this.elementRef.nativeElement);
    manager.add(new Swipe({direction: DIRECTION_HORIZONTAL, threshold: 0}));
    manager.on('swipeleft', (event) => this.next());
    manager.on('swiperight', (event) => this.previous());
  }

  next(): void {
    if (this.notifications.length <= this.current + 1) {
      return;
    }
    this.current++;
    this.animateHeight(true);
    this.cdr.markForCheck();
  }

  previous(): void {
    if (this.current === 0) {
      return;
    }
    this.current--;
    this.animateHeight(false);
    this.cdr.markForCheck();
  }

  animateHeight(next?: boolean): void {
    const oldHeight = this.wrapper.nativeElement.clientHeight;
    const oldTransition = this.wrapper.nativeElement.style.transition;
    this.wrapper.nativeElement.style.transition = '';
    this.wrapper.nativeElement.style.height = null;
    requestAnimationFrame(() => {
      const newHeight = next
        ? this.wrapper.nativeElement.children[1].clientHeight
        : this.wrapper.nativeElement.children[0].clientHeight;
      this.wrapper.nativeElement.style.height = oldHeight + 'px';
      requestAnimationFrame(() => {
        this.wrapper.nativeElement.style.height = newHeight + 'px';
        this.wrapper.nativeElement.style.transition = oldTransition;
      });
    });
  }

  doAction(item: NotificationItem, action: NotificationItemAction): void {
    if (this.loadings[item.model.id]) {
      return;
    }
    this.loadings[item.model.id] = action;
    action.action(item.model).pipe(
      finalize(() => this.loadings[item.model.id] = null)
    ).subscribe(() => {
      if (action.markViewed) {
        item.model.viewed = true;
        this.notificationDataService.markViewedProtected(item.model);
      }
      this.cdr.markForCheck();
    }, error => {
      handleError(error);
      this.toastService.danger('Произошла ошибка');
    });
  }

  openSettings(): void {
    this.router.navigate(this.notificationSettingsRoute);
    if (this.isMobile) {
      this.hide.emit();
    }
  }
}
