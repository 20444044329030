import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastService } from '@ui-kit/toast/toast.service';

@Component({
  selector: 'mtg-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true
    }
  ]
})
export class FileUploaderComponent implements ControlValueAccessor {
  constructor(private toastService: ToastService) {}

  private _onChangeFunc: any;
  private _onTouchedFunc: any;
  private _value: File[] = [];

  @Input()
  title: string;
  @Input()
  set value(value: File[]) {
    this._value = value || [];
  }
  get value(): File[] {
    return this._value;
  }
  @Input()
  disabled: boolean;
  @Input()
  maxFileSize: number;
  @Input()
  type: 'file' | 'image' = 'file';

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedFunc = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  inputChanged($event): void {
    const files = [];
    for (const file of $event.target.files) {
      files.push(file);
    }
    this.addFile(files);
  }

  addFile(files: File[]): void {
    if (this.maxFileSize) {
      const filteredFiles: File[] = [];

      files.forEach((file: File) => {
        if (file.size > this.maxFileSize) {
          this.toastService.danger(`Файл ${file.name} нельзя загрузить потому, что он больше ${this.maxFileSize / 1024 / 1024}МБ`);
        } else {
          filteredFiles.push(file);
        }
      });

      files = filteredFiles;
    }

    this.value = [...this.value, ...files];
    if (this._onChangeFunc) {
      this._onChangeFunc(this.value);
    }
    if (this._onTouchedFunc) {
      this._onTouchedFunc();
    }
  }

  removeFile(file: File): void {
    this.value.splice(this.value.indexOf(file), 1);
    if (this._onChangeFunc) {
      this._onChangeFunc(this.value);
    }
    if (this._onTouchedFunc) {
      this._onTouchedFunc();
    }
  }
}
