import {
  Component,
  ElementRef, EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { isTouchDevice } from '@shared/util/device';
import { ERRORS_TRANSLATION } from '@ui-kit/form/components/input/input.component';

@Component({
  selector: 'mtg-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent implements ControlValueAccessor, OnInit {
  constructor(
    @Optional() private _parentFormGroup: FormGroupDirective
  ) {}

  ngOnInit() {
    if (this._parentFormGroup) {
      this._control = this._parentFormGroup.control.get(this.formControlName);
    }
  }

  private _value;
  private _control: AbstractControl;
  private _onChangeFunc: any;
  private _onTouchedFunc: any;
  private _isFocused: boolean;

  @ViewChild('textarea', {read: ElementRef, static: true})
  textarea: ElementRef;

  @Input() placeholder: string;
  @Input() label: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() formControlName: string;
  @Input() fluid = true;
  @Input() errors: any;
  @Input() transparent: boolean;
  @Input() readOnly: boolean;
  @Input() formControl: FormControl;

  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();

  @HostBinding('class')
  get className() {
    const value = this.textarea.nativeElement.value;
    const classList = [];
    if (this.fluid) {
      classList.push('fluid');
    }
    if (this._control && this._control.dirty && !this._control.valid) {
      classList.push('error');
    } else if (this.errors) {
      classList.push('error');
    }
    if (value.toString().length > 0) {
      classList.push('filled');
    }
    if (this.placeholder) {
      classList.push('with-placeholder');
    }
    if (!this.label) {
      classList.push('without-label');
    }
    if (this.transparent) {
      classList.push('transparent');
    }
    if (this._isFocused) {
      classList.push('focused');
    }
    return classList.join(' ');
  }

  changeValue(value: any) {
    this._value = value;
    this._onChangeFunc(this._value);
    this._onTouchedFunc();
    this.textarea.nativeElement.value = value;
  }

  onFocus($event: any): void {
    this._isFocused = true;
    this.focus.emit($event);
    if (isTouchDevice()) {
      document.body.classList.add('input-focused');
    }
  }

  onBlur($event: any): void {
    this._isFocused = false;
    if (this._onTouchedFunc) {
      this._onTouchedFunc();
    }
    if (isTouchDevice()) {
      document.body.classList.remove('input-focused');
    }
    this.blur.emit($event);
  }

  writeValue(value: any): void {
    this._value = value;
    this.textarea.nativeElement.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedFunc = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getErrors() {
    const control = (this._control || this.formControl);
    let errors = {};
    if (control && control.dirty && control.errors) {
      errors = control.errors;
    } else if (this.errors) {
      errors = this.errors;
    }
    return Object.keys(errors)
      .filter(k => errors[k])
      .reduce((acc, value) => {
        let error = ERRORS_TRANSLATION[value];
        if (!error) {
          return acc;
        }
        if (typeof errors[value] === 'object') {
          for (const errorKey of Object.keys(errors[value])) {
            error = error.replace(`{${errorKey}}`, errors[value][errorKey]);
          }
        }
        acc.push(error);
        return acc;
      }, []);
  }
}
