<div class="team-event-item"
     [routerLink]="teamEventDetailRoute(teamEvent.id)"
     *ngIf="teamEvent"
>
  <div class="headline">
    <div class="datetime">
      <a (click)="downloadCalendarFile()" *ngIf="!teamEvent.isClosed" class="link" mtgStopPropagation>
        <span class="date">
          <span class="icon-calendar-add"></span>
          {{teamEvent.datetime | date: 'd MMM'}}
        </span>
        <span class="time">
          {{teamEvent.datetime | date: 'HH:mm'}}
        </span>
      </a>
      <ng-container *ngIf="teamEvent.isClosed">
        <span class="date">
          {{teamEvent.datetime | date: 'd MMM'}}
        </span>
        <span class="time">
          {{teamEvent.datetime | date: 'HH:mm'}}
        </span>
      </ng-container>
    </div>
    <div class="tag" [ngClass]="{primary: !teamEvent.isClosed}">
      {{ teamEvent.eventType | localization: teamEventTypeLocalization }}
    </div>
  </div>
  <div class="team-event-details">
    <div class="team-meta">
      <div class="photo">
        <img [src]="team.logo.path" *ngIf="team.logo.path">
        <div [class]="team.getEmptyLogoClass()" *ngIf="!team.logo.path"></div>
      </div>
      <div>{{ team.name }}</div>
    </div>
    <div class="event-details">
      <div class="name">{{ teamEvent.name }}</div>
    </div>
  </div>
  <div class="controls">
    <div class="location"><span class="icon-location"></span> {{ teamEvent.location }}</div>
    <a class="btn btn-circle muted pull-right"
       mtgStopPropagation
       [routerLink]="editTeamEventRoute(teamEvent.id)"
       *ngIf="canManage"
    >
      <i class="icon-edit"></i>
    </a>
  </div>
</div>
