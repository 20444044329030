<div class="layout">
  <div class="content">
    <mtg-header></mtg-header>

    <ng-content></ng-content>

    <div class="footer">
      <a href="javascript:;" class="link" [routerLink]="agreementRoute">Политика конфиденциальности</a>
      <a href="javascript:;" class="link" (click)="openFeedbackForm()">Обратная связь</a>
      <!--<a href="#">Публичная оферта</a>-->
      <div class="copyright">
        MTGame © {{ year }} • Все права защищены
      </div>
    </div>
  </div>
</div>
