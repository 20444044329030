import { Injectable } from '@angular/core';
import { combineLatest, forkJoin } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { TeamService } from './team.service';
import { PollService } from './poll.service';
import { GameService } from './game.service';
import { AuthService } from './auth.service';
import { Team } from '../models/team';
import { TeamInvite } from '../models/team-invite';
import { TeamUser, TeamUserRole } from '../models/team-user';
import { Poll, PollStatuses } from '../models/poll';
import { Game } from '../models/game';
import { TeamUserStatistic } from '../models/team-user-statistic';
import { User } from '../models/user';
import { TeamInviteExternal } from '../models/team-invite-external';
import { addItemInArray, deleteItemFromArray, updateItemInArray } from '@shared/util/data';
import { UserService } from '@core/services/user.service';
import { CentrifugoService } from '@core/services/centrifugo.service';
import { TeamEvent } from '@core/models/team-event';
import { TeamEventService } from '@core/services/team-event.service';
import { Tournament } from '@core/models/tournament';
import { TournamentInvite } from '@core/models/tournament-invite';
import { TeamAccess } from '@core/models/team-access';
import { TeamPermission } from '@core/models/team-permission';
import { VolleyballStatistic } from '@core/models/volleyball-statistic';
import { BasketballGameDetailService } from '@core/services/basketball-game-detail.service';
import { VolleyballGameDetailService } from '@core/services/volleyball-game-detail.service';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { TournamentTeam } from '@core/models/tournament-team';
import { FootballStatistic } from '@core/models/football-statistic';
import { FootballGameDetailService } from '@core/services/football-game-detail.service';
import { HandballStatistic } from '@core/models/handball-statistic';
import { HandballGameDetailService } from '@core/services/handball-game-detail.service';
import { BasketballStatistic } from '@core/models/basketball-statistic';
import { RugbyStatistic } from '@core/models/rugby-statistic';
import { RugbyGameDetailService } from '@core/services/rugby-game-detail.service';
import { HockeyGameDetailService } from '@core/services/hockey-game-detail.service';
import { HockeyStatistic } from '@core/models/hockey-statistic';
import { WaterpoloStatistic } from '@core/models/waterpolo-statistic';
import { WaterpoloGameDetailService } from '@core/services/waterpolo-game-detail.service';

@Injectable()
export class TeamProfileService {
  private _myTeamsSubject = new BehaviorSubject<Team[]>(undefined);
  private _myInviteSubject = new BehaviorSubject<TeamInvite>(undefined);
  private _teamSubject = new BehaviorSubject<Team>(undefined);
  private _usersSubject = new BehaviorSubject<TeamUser[]>(undefined);
  private _invitesSubject = new BehaviorSubject<TeamInvite[]>(undefined);
  private _invitesExternalSubject = new BehaviorSubject<TeamInviteExternal[]>(undefined);
  private _pollsSubject = new BehaviorSubject<Poll[]>(undefined);
  private _archivedPollsSubject = new BehaviorSubject<Poll[]>(undefined);
  private _gamesSubject = new BehaviorSubject<Game[]>(undefined);
  private _archivedGamesSubject = new BehaviorSubject<Game[]>(undefined);
  private _eventsSubject = new BehaviorSubject<TeamEvent[]>(undefined);
  private _userStatisticSubject = new BehaviorSubject<BasketballStatistic[]>(undefined);
  private _volleyballStatisticSubject = new BehaviorSubject<VolleyballStatistic[]>(undefined);
  private _footballStatisticSubject = new BehaviorSubject<FootballStatistic[]>(undefined);
  private _handballStatisticSubject = new BehaviorSubject<HandballStatistic[]>(undefined);
  private _rugbyStatisticSubject = new BehaviorSubject<RugbyStatistic[]>(undefined);
  private _hockeyStatisticSubject = new BehaviorSubject<HockeyStatistic[]>(undefined);
  private _waterpoloStatisticSubject = new BehaviorSubject<WaterpoloStatistic[]>(undefined);
  private _tournamentsSubject = new BehaviorSubject<Tournament[]>(undefined);
  private _tournamentTeamsSubject = new BehaviorSubject<TournamentTeam[]>(undefined);
  private _tournamentInvitesSubject = new BehaviorSubject<TournamentInvite[]>(undefined);
  private _accessSubject = new BehaviorSubject<TeamAccess>(undefined);
  private _permissionSubject = new BehaviorSubject<TeamPermission>(undefined);

  get myTeams$() {
    return this._myTeamsSubject.pipe(filter(item => item !== undefined));
  }

  get myInvite$() {
    return this._myInviteSubject.pipe(filter(item => item !== undefined));
  }

  get team$() {
    return this._teamSubject.pipe(filter(item => !!item));
  }

  get permission$() {
    return this._permissionSubject.pipe(filter(item => item !== undefined));
  }

  get access$() {
    return this._accessSubject.pipe(filter(item => item !== undefined));
  }

  get users$() {
    return this._usersSubject.pipe(filter(item => item !== undefined));
  }

  get invites$() {
    return this._invitesSubject.pipe(filter(item => item !== undefined));
  }

  get invitesExternal$() {
    return this._invitesExternalSubject.pipe(filter(item => item !== undefined));
  }

  get polls$() {
    return this._pollsSubject.pipe(filter(item => item !== undefined));
  }

  get archivedPolls$() {
    return this._archivedPollsSubject.pipe(filter(item => item !== undefined));
  }

  get games$() {
    return this._gamesSubject.pipe(filter(item => item !== undefined));
  }

  get archivedGames$() {
    return this._archivedGamesSubject.pipe(filter(item => item !== undefined));
  }

  get events$() {
    return this._eventsSubject.pipe(filter(item => item !== undefined));
  }

  get userStatistic$() {
    return this._userStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get volleyballStatistic$() {
    return this._volleyballStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get footballStatistic$() {
    return this._footballStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get handballStatistic$() {
    return this._handballStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get rugbyStatistic$() {
    return this._rugbyStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get hockeyStatistic$() {
    return this._hockeyStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get waterpoloStatistic$() {
    return this._waterpoloStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get tournaments$() {
    return this._tournamentsSubject.pipe(filter(item => item !== undefined));
  }

  get tournamentTeams$() {
    return this._tournamentTeamsSubject.pipe(filter(item => item !== undefined));
  }

  get tournamentInvites$() {
    return this._tournamentInvitesSubject.pipe(filter(item => item !== undefined));
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private teamService: TeamService,
    private pollService: PollService,
    private gameService: GameService,
    private teamEventService: TeamEventService,
    private centrifugoService: CentrifugoService,
    private basketballGameDetailService: BasketballGameDetailService,
    private volleyballGameDetailService: VolleyballGameDetailService,
    private footballGameDetailService: FootballGameDetailService,
    private handballGameDetailService: HandballGameDetailService,
    private rugbyGameDetailService: RugbyGameDetailService,
    private hockeyGameDetailService: HockeyGameDetailService,
    private waterpoloGameDetailService: WaterpoloGameDetailService,
  ) {}

  initialize(teamId: number) {
    this.listenCentrifugoEvents(teamId);

    return forkJoin([
      this.getTeam(teamId),
      this.getAccess(teamId)
    ]).pipe(
      mergeMap(([team, access]) => {
        const load = [];
        load.push(this.getUsers(teamId));
        load.push(this.getMyTeams());
        load.push(this.getTournamentTeams(teamId));
        load.push(this.getPermission(teamId));
        if (access.canManageInvites) {
          load.push(this.getInvites(teamId));
          load.push(this.getInvitesExternal(teamId));
        } else {
          this._invitesSubject.next([]);
          this._invitesExternalSubject.next([]);
        }
        if (access.canManageTournaments) {
          load.push(this.getTournamentInvites(teamId));
        } else {
          this._tournamentInvitesSubject.next([]);
        }
        if (access.canViewPolls) {
          load.push(this.getPolls(teamId));
        } else {
          this._pollsSubject.next([]);
        }
        if (access.canViewGames) {
          load.push(this.getGames(teamId));
        } else {
          this._gamesSubject.next([]);
        }
        if (access.canViewEvents) {
          load.push(this.getEvents(teamId));
        } else {
          this._eventsSubject.next([]);
        }
        if (access.canViewStatistic) {
          if (team.sport.isBasketball()) {
            load.push(this.getUserStatistics(teamId));
          }
          if (team.sport.isVolleyball()) {
            load.push(this.getVolleyballStatistics(teamId));
          }
          if (team.sport.isFootball()) {
            load.push(this.getFootballStatistics(teamId));
          }
          if (team.sport.isHandball()) {
            load.push(this.getHandballStatistics(teamId));
          }
          if (team.sport.isRugby()) {
            load.push(this.getRugbyStatistics(teamId));
          }
          if (team.sport.isHockey()) {
            load.push(this.getHockeyStatistics(teamId));
          }
          if (team.sport.isWaterpolo()) {
            load.push(this.getWaterpoloStatistics(teamId));
          }
        } else {
          this._userStatisticSubject.next([]);
        }
        if (!access.role) {
          load.push(this.getMyInvites(teamId));
        }
        return forkJoin(load);
      })
    );
  }

  dispose() {
    if (this._teamSubject.value) {
      this.centrifugoService.unsubscribe(`team_${this._teamSubject.value.id}`);
      this._myTeamsSubject.next(undefined);
      this._teamSubject.next(undefined);
      this._permissionSubject.next(undefined);
      this._accessSubject.next(undefined);
      this._usersSubject.next(undefined);
      this._invitesSubject.next(undefined);
      this._invitesExternalSubject.next(undefined);
      this._pollsSubject.next(undefined);
      this._gamesSubject.next(undefined);
      this._userStatisticSubject.next(undefined);
      this._eventsSubject.next(undefined);
      this._tournamentsSubject.next(undefined);
      this._tournamentTeamsSubject.next(undefined);
      this._tournamentInvitesSubject.next(undefined);
      this._footballStatisticSubject.next(undefined);
      this._handballStatisticSubject.next(undefined);
      this._hockeyStatisticSubject.next(undefined);
      this._waterpoloStatisticSubject.next(undefined);
    }
  }

  getMyTeams(): Observable<Team[]> {
    return this.authService.user$.pipe(
      filter(user => user !== null),
      take(1),
      switchMap(user => this.userService.getTeams(user.id)),
      tap(teams => {
        this._myTeamsSubject.next(teams);
      }),
    );
  }

  getTeam(teamId: number): Observable<Team> {
    return this.teamService.getById(teamId).pipe(
      tap(team => this._teamSubject.next(team)),
    );
  }

  updateTeam(teamId: number, values: any): Observable<Team> {
    return this.teamService.update(teamId, values).pipe(
      tap(result => this._teamSubject.next(result)),
      tap(result => this._myTeamsSubject.next(updateItemInArray(this._myTeamsSubject.value, result)))
    );
  }

  getAccess(teamId: number): Observable<TeamAccess> {
    return this.teamService.getTeamAccess(teamId).pipe(
      tap(result => this._accessSubject.next(result))
    );
  }

  getPermission(teamId: number): Observable<TeamPermission> {
    return this.teamService.getPermission(teamId).pipe(
      tap(result => this._permissionSubject.next(result))
    );
  }

  updatePermission(teamId: number, permission: TeamPermission): Observable<TeamPermission> {
    return this.teamService.updatePermission(teamId, permission).pipe(
      tap(result => this._permissionSubject.next(result))
    );
  }

  getUsers(teamId: number): Observable<TeamUser[]> {
    return this.teamService.getUsers(teamId).pipe(
      tap(users => this._usersSubject.next(users)),
    );
  }

  getUsersForTournament(tournamentId: number): Observable<TeamUser[]> {
    if (!this._teamSubject.value) {
      throw new Error('Team profile is not initialized');
    }
    return this.teamService.getUsers(this._teamSubject.value.id, tournamentId);
  }

  updateUserRole(teamUser: TeamUser, role: TeamUserRole): Observable<TeamUser> {
    return this.teamService
      .updateUserRole(teamUser.teamId, teamUser.id, role)
      .pipe(
        tap(result => {
          this._usersSubject.next(updateItemInArray(this._usersSubject.value, result));
        })
      );
  }

  updateUserNumber(teamId: number, teamUserId: number, number: number): Observable<TeamUser> {
    return this.teamService
      .updateUserNumber(teamId, teamUserId, number)
      .pipe(
        tap(result => this._usersSubject.next(updateItemInArray(this._usersSubject.value, result)))
      );
  }

  deleteUser(teamUser: TeamUser): Observable<any> {
    return this.teamService.deleteUser(teamUser).pipe(
      tap(() => {
        this._usersSubject.next(deleteItemFromArray(this._usersSubject.value, teamUser));
      })
    );
  }

  changeOwner(teamId: number, user: User): Observable<Team> {
    return this.teamService.changeOwner(teamId, user).pipe(
      tap(result => {
        const users = this._usersSubject.value.map(u => {
          if (u.user.id === user.id) {
            u.role = TeamUserRole.admin;
          } else if (u.role === TeamUserRole.admin) {
            u.role = TeamUserRole.moderator;
          }
          return u;
        });
        this._usersSubject.next(users);
      }),
      tap(result => this._teamSubject.next(result)),
      tap(() => this.getAccess(teamId).subscribe())
    );
  }

  getInvites(teamId: number): Observable<TeamInvite[]> {
    return this.teamService.getInvites(teamId).pipe(
      tap(invites => this._invitesSubject.next(invites)),
    );
  }

  joinTeam(teamId: number): Observable<TeamInvite> {
    return this.teamService.joinTeam(teamId).pipe(
      tap(invite => this._myInviteSubject.next(invite))
    );
  }

  leaveTeam(teamId: number): Observable<any> {
    return this.teamService.leaveTeam(teamId).pipe(
      switchMap(result =>
        this.authService.user$.pipe(
          take(1),
          tap(user => this._usersSubject.next(deleteItemFromArray(this._usersSubject.value, item => item.user.id === user.id))),
          map(() => result)
        )
      ),
      tap(() => this.getAccess(teamId).subscribe())
    );
  }

  sendInvite(teamId: number, user: User): Observable<TeamInvite> {
    return this.teamService.sendInvite(teamId, user).pipe(
      tap(invite => {
        this._invitesSubject.next(addItemInArray(this._invitesSubject.value, invite));
      })
    );
  }

  getInvitesExternal(teamId: number): Observable<TeamInviteExternal[]> {
    return this.teamService.getInvitesExternal(teamId).pipe(
      tap(invites => this._invitesExternalSubject.next(invites))
    );
  }

  sendInviteExternal(teamId: number, invite: TeamInviteExternal): Observable<TeamInviteExternal> {
    return this.teamService.sendInviteExternal(teamId, invite).pipe(
      tap(result => this._invitesExternalSubject.next(addItemInArray(this._invitesExternalSubject.value, result)))
    );
  }

  declineInviteExternal(invite: TeamInviteExternal): Observable<any> {
    return this.teamService.deleteInviteExternal(invite).pipe(
      tap(() => this._invitesExternalSubject.next(deleteItemFromArray(this._invitesExternalSubject.value, invite)))
    );
  }

  resendInviteExternal(invite: TeamInviteExternal): Observable<TeamInviteExternal> {
    return this.teamService.resendInviteExternal(invite).pipe(
      tap(result => this._invitesExternalSubject.next(updateItemInArray(this._invitesExternalSubject.value, result)))
    );
  }

  acceptInvite(invite: TeamInvite): Observable<TeamInvite> {
    return this.teamService.acceptInvite(invite).pipe(
      tap(result => {
        this._invitesSubject.next(deleteItemFromArray(this._invitesSubject.value, result));
        this.getUsers(invite.teamId).subscribe();
      })
    );
  }

  declineInvite(invite: TeamInvite): Observable<TeamInvite> {
    return this.teamService.declineInvite(invite).pipe(
      tap(result => {
        this._invitesSubject.next(deleteItemFromArray(this._invitesSubject.value, result));
      })
    );
  }

  resendInvite(invite: TeamInvite): Observable<TeamInvite> {
    return this.teamService.resendInvite(invite).pipe(
      tap(result => this._invitesSubject.next(updateItemInArray(this._invitesSubject.value, result)))
    );
  }

  getMyInvites(teamId: number): Observable<TeamInvite> {
    return this.userService.getInvites().pipe(
      map(result => result.find(item => item.teamId === +teamId)),
      tap(result => this._myInviteSubject.next(result || null))
    );
  }

  getPolls(teamId: number): Observable<Poll[]> {
    return this.teamService.getPolls(teamId).pipe(
      tap(polls => this._pollsSubject.next(polls)),
    );
  }

  getArchivedPolls(teamId: number): Observable<Poll[]> {
    return this.teamService.getPolls(teamId, true).pipe(
      tap(result => this._archivedPollsSubject.next(result)),
    );
  }

  createPoll(teamId: number, poll: Poll): Observable<Poll> {
    return this.teamService.createPoll(teamId, poll).pipe(
      tap(result => this._pollsSubject.next(addItemInArray(this._pollsSubject.value, result)))
    );
  }

  updatePoll(poll: Poll): Observable<Poll> {
    return this.pollService.updatePoll(poll).pipe(
      tap(result => this._pollsSubject.next(updateItemInArray(this._pollsSubject.value, result)))
    );
  }

  updatePollValue(poll: Poll): void {
    if (poll.status === PollStatuses.archived) {
      this._pollsSubject.next(deleteItemFromArray(this._pollsSubject.value, poll));
    } else {
      this._pollsSubject.next(updateItemInArray(this._pollsSubject.value, poll));
    }
  }

  deletePoll(poll: Poll): void {
    this._pollsSubject.next(deleteItemFromArray(this._pollsSubject.value, poll));
  }

  getGames(teamId: number): Observable<Game[]> {
    return this.teamService.getGames(teamId).pipe(
      tap(games => this._gamesSubject.next(games))
    );
  }

  getArchivedGames(teamId: number): Observable<Game[]> {
    return this.teamService.getGames(teamId, true).pipe(
      tap(games => this._archivedGamesSubject.next(games))
    );
  }

  createGame(teamId: number, game: Game): Observable<Game> {
    return this.teamService.createGame(teamId, game).pipe(
      tap(result => this._gamesSubject.next(addItemInArray(this._gamesSubject.value, result))),
    );
  }

  updateGame(gameId: number, data: any): Observable<Game> {
    return this.gameService.updateGameById(gameId, data).pipe(
      tap(result => this._gamesSubject.next(updateItemInArray(this._gamesSubject.value, result)))
    );
  }

  updateGameValue(game: Game): void {
    this._gamesSubject.next(updateItemInArray(this._gamesSubject.value, game));
  }

  deleteGame(game: Game): Observable<any> {
    return this.gameService.deleteGame(game).pipe(
      tap(() => this._gamesSubject.next(deleteItemFromArray(this._gamesSubject.value, game)))
    );
  }

  archiveGame(game: Game): Observable<Game> {
    return this.gameService.archiveGame(game).pipe(
      tap(result => this._gamesSubject.next(deleteItemFromArray(this._gamesSubject.value, result))),
    );
  }

  closeGame(game: Game): Observable<Game> {
    return this.gameService.closeGame(game).pipe(
      tap(result => this._gamesSubject.next(updateItemInArray(this._gamesSubject.value, result)))
    );
  }

  getEvents(teamId: number): Observable<TeamEvent[]> {
    return this.teamService.getTeamEvents(teamId).pipe(
      tap(events => this._eventsSubject.next(events))
    );
  }

  createEvent(teamId: number, teamEvent: TeamEvent): Observable<TeamEvent> {
    return this.teamService.createTeamEvent(teamId, teamEvent).pipe(
      tap(event => this._eventsSubject.next(addItemInArray(this._eventsSubject.value, event)))
    );
  }

  updateEvent(teamEvent: TeamEvent): Observable<TeamEvent> {
    return this.teamEventService.updateTeamEvent(teamEvent).pipe(
      tap(event => this._eventsSubject.next(updateItemInArray(this._eventsSubject.value, event)))
    );
  }

  deleteEvent(teamEventId: number): Observable<any> {
    return this.teamEventService.deleteTeamEvent(teamEventId).pipe(
      tap(() => this._eventsSubject.next(deleteItemFromArray(this._eventsSubject.value, item => item.id === teamEventId)))
    );
  }

  getUserStatistics(teamId: number): Observable<BasketballStatistic[]> {
    return this.teamService.getUsersStatistic(teamId).pipe(
      tap(userStatistic => this._userStatisticSubject.next(userStatistic)),
    );
  }

  getVolleyballStatistics(teamId: number): Observable<VolleyballStatistic[]> {
    return this.teamService.getVolleyballStatistic(teamId).pipe(
      tap(statistic => this._volleyballStatisticSubject.next(statistic))
    );
  }

  getFootballStatistics(teamId: number): Observable<FootballStatistic[]> {
    return this.teamService.getFootballStatistic(teamId).pipe(
      tap(statistic => this._footballStatisticSubject.next(statistic))
    );
  }

  getHandballStatistics(teamId: number): Observable<HandballStatistic[]> {
    return this.teamService.getHandballStatistic(teamId).pipe(
      tap(statistic => this._handballStatisticSubject.next(statistic))
    );
  }

  getRugbyStatistics(teamId: number): Observable<RugbyStatistic[]> {
    return this.teamService.getRugbyStatistic(teamId).pipe(
      tap(statistic => this._rugbyStatisticSubject.next(statistic))
    );
  }

  getHockeyStatistics(teamId: number): Observable<HockeyStatistic[]> {
    return this.teamService.getHockeyStatistic(teamId).pipe(
      tap(statistic => this._hockeyStatisticSubject.next(statistic))
    );
  }

  getWaterpoloStatistics(teamId: number): Observable<WaterpoloStatistic[]> {
    return this.teamService.getWaterpoloStatistic(teamId).pipe(
      tap(statistic => this._waterpoloStatisticSubject.next(statistic))
    );
  }

  getTournaments(teamId: number): Observable<Tournament[]> {
    return this.teamService.getTournaments(teamId).pipe(
      tap(tournaments => this._tournamentsSubject.next(tournaments))
    );
  }

  getTournamentTeams(teamId: number): Observable<TournamentTeam[]> {
    return this.teamService.getTournamentTeams(teamId).pipe(
      tap(tournamentTeams => this._tournamentTeamsSubject.next(tournamentTeams))
    );
  }

  getTournamentInvites(teamId: number): Observable<TournamentInvite[]> {
    return this.teamService.getTournamentInvites(teamId).pipe(
      tap(invites => this._tournamentInvitesSubject.next(invites))
    );
  }

  getTournamentGames(tournamentId: number, page: number = 1, size?: number): Observable<PaginatedResponse<Game[]>> {
    if (!this._teamSubject.value) {
      throw new Error('Team profile is not initialized');
    }
    return this.teamService.getTournamentGames(this._teamSubject.value.id, tournamentId, page, size);
  }

  acceptTournamentInvite(inviteId: number): Observable<TournamentInvite> {
    return this.teamService.acceptTournamentInvite(inviteId).pipe(
      tap(invite => {
        this._tournamentInvitesSubject.next(deleteItemFromArray(this._tournamentInvitesSubject.value, invite));
        this._tournamentsSubject.next(addItemInArray(this._tournamentsSubject.value, invite.tournament));
      })
    );
  }

  declineTournamentInvite(inviteId: number): Observable<TournamentInvite> {
    return this.teamService.declineTournamentInvite(inviteId).pipe(
      tap(invite => {
        this._tournamentInvitesSubject.next(deleteItemFromArray(this._tournamentInvitesSubject.value, invite));
      })
    );
  }

  registerLazyUser( userData: User): Observable<TeamUser> {
    if (!this._teamSubject.value) {
      throw new Error('Team is not initialized');
    }
    return this.teamService.registerLazyUser(this._teamSubject.value.id, userData).pipe(
      tap(teamUser => this._usersSubject.next(addItemInArray(this._usersSubject.value, teamUser, true)))
    );
  }

  getLazyUser(teamUserId: number): Observable<TeamUser> {
    if (!this._teamSubject.value) {
      throw new Error('Team is not initialized');
    }
    return this.teamService.getLazyUser(teamUserId);
  }

  updateLazyUser(teamUserId: number, userData: User): Observable<TeamUser> {
    return this.teamService.updateLazyUser(teamUserId, userData).pipe(
      tap(teamUser => {
        this._usersSubject.next(updateItemInArray(this._usersSubject.value, teamUser));
      })
    );
  }

  deleteLazyUser(teamUserId: number): Observable<any> {
    return this.teamService.deleteLazyUser(teamUserId).pipe(
      tap(() => {
        this._usersSubject.next(deleteItemFromArray(this._usersSubject.value, item => item.id === teamUserId));
      })
    );
  }

  resendLazyUserEmail(teamUserId: number): Observable<any> {
    return this.teamService.resendLazyUserEmail(teamUserId);
  }

  getTeamModel(): Team {
    return this._teamSubject.value;
  }

  getGameDetailService() {
    if (!this._teamSubject.value) {
      throw new Error('Team is not initialized');
    }
    const team = this._teamSubject.value;
    return team.sport.isVolleyball()
      ? this.volleyballGameDetailService
      : team.sport.isFootball()
      ? this.footballGameDetailService
      : team.sport.isBasketball()
      ? this.basketballGameDetailService
      : team.sport.isHandball()
      ? this.handballGameDetailService
      : team.sport.isRugby()
      ? this.rugbyGameDetailService
      : team.sport.isHockey()
      ? this.hockeyGameDetailService
      : team.sport.isWaterpolo()
      ? this.waterpoloGameDetailService
      : null;
  }

  private listenCentrifugoEvents(teamId) {
    this.centrifugoService
      .listen(`team_${teamId}`)
      .subscribe(message => {
        switch (message['action']) {
          case 'TEAM_UPDATED': {
            this._teamSubject.next(Team.toFront(message['data']));
            this._myTeamsSubject.next(updateItemInArray(this._myTeamsSubject.value, Team.toFront(message['data'])));
            return;
          }

          case 'TEAM_OWNER_CHANGED': {
            const team = this._teamSubject.value;
            if (team) {
              team.owner = User.toFront(message['data']);
              this._teamSubject.next(team);
            }
            return;
          }

          case 'TEAM_USER_ADDED': {
            return this._usersSubject.next(addItemInArray(this._usersSubject.value, TeamUser.toFront(message['data'])));
          }

          case 'TEAM_USER_UPDATED': {
            return this._usersSubject.next(updateItemInArray(this._usersSubject.value, TeamUser.toFront(message['data'])));
          }

          case 'TEAM_USER_DELETED': {
            return this._usersSubject.next(
              deleteItemFromArray(this._usersSubject.value, u => u.user.id === message['data']['user']['id'])
            );
          }

          case 'TEAM_INVITE_CREATED': {
            return this._invitesSubject.next(addItemInArray(this._invitesSubject.value, TeamInvite.toFront(message['data'])));
          }

          case 'TEAM_INVITE_DELETED': {
            return this._invitesSubject.next(
              deleteItemFromArray(this._invitesSubject.value, item => item.user.id === message['data']['user']['id'])
            );
          }

          case 'TEAM_INVITE_EXTERNAL_CREATED': {
            return this._invitesExternalSubject.next(
              addItemInArray(this._invitesExternalSubject.value, TeamInviteExternal.toFront(message['data']))
            );
          }

          case 'TEAM_INVITE_EXTERNAL_DELETED': {
            return this._invitesExternalSubject.next(
              deleteItemFromArray(this._invitesExternalSubject.value, TeamInviteExternal.toFront(message['data']))
            );
          }

          case 'POLL_CREATED': {
            return this._pollsSubject.next(addItemInArray(this._pollsSubject.value, Poll.toFront(message['data'])));
          }

          case 'POLL_UPDATED': {
            return this._pollsSubject.next(updateItemInArray(this._pollsSubject.value, Poll.toFront(message['data'])));
          }

          case 'POLL_STATUS_CHANGED': {
            const poll = Poll.toFront(message['data']);
            if (poll.status === PollStatuses.archived) {
              this._pollsSubject.next(deleteItemFromArray(this._pollsSubject.value, poll));
              if (this._archivedPollsSubject.value) {
                this._archivedPollsSubject.next(addItemInArray(this._archivedPollsSubject.value, poll));
              }
            } else {
              this._pollsSubject.next(updateItemInArray(this._pollsSubject.value, poll));
            }
            return;
          }

          case 'POLL_DELETED': {
            return this._pollsSubject.next(deleteItemFromArray(this._pollsSubject.value, Poll.toFront(message['data'])));
          }

          case 'POLL_ANSWERED': {
            return this._pollsSubject.next(updateItemInArray(this._pollsSubject.value, Poll.toFront(message['data'])));
          }

          case 'GAME_CREATED': {
            return this._gamesSubject.next(addItemInArray(this._gamesSubject.value, Game.toFront(message['data'])));
          }

          case 'GAME_UPDATED': {
            return this._gamesSubject.next(updateItemInArray(this._gamesSubject.value, Game.toFront(message['data'])));
          }

          case 'GAME_STATISTIC_UPDATED': {
            return this._gamesSubject.next(updateItemInArray(this._gamesSubject.value, Game.toFront(message['data'])));
          }

          case 'GAME_ARCHIVED': {
            this._gamesSubject.next(deleteItemFromArray(this._gamesSubject.value, Game.toFront(message['data'])));
            if (this._archivedGamesSubject.value) {
              this._archivedGamesSubject.next(addItemInArray(this._archivedGamesSubject.value, Game.toFront(message['data'])));
            }
            return;
          }

          case 'GAME_CLOSED': {
            if (this._teamSubject.value.sport.isBasketball()) {
              this.getUserStatistics(this._teamSubject.value.id).subscribe();
            }
            if (this._teamSubject.value.sport.isVolleyball()) {
              this.getVolleyballStatistics(this._teamSubject.value.id).subscribe();
            }
            this.getTeam(this._teamSubject.value.id).subscribe();
            return this._gamesSubject.next(updateItemInArray(this._gamesSubject.value, Game.toFront(message['data'])));
          }

          case 'GAME_DELETED': {
            return this._gamesSubject.next(deleteItemFromArray(this._gamesSubject.value, Game.toFront(message['data'])));
          }

          case 'TOURNAMENT_INVITE_CREATED': {
            return this._tournamentInvitesSubject.next(
              addItemInArray(this._tournamentInvitesSubject.value, TournamentInvite.toFront(message['data']))
            );
          }

          case 'TOURNAMENT_INVITE_DECLINED': {
            return this._tournamentInvitesSubject.next(
              deleteItemFromArray(this._tournamentInvitesSubject.value, TournamentInvite.toFront(message['data']))
            );
          }

          case 'TOURNAMENT_TEAM_CREATED': {
            const tournamentTeam = TournamentTeam.toFront(message['data']);
            this._tournamentInvitesSubject.next(
              deleteItemFromArray(this._tournamentInvitesSubject.value, item => item.teamId === tournamentTeam.teamId &&
                item.tournamentId === tournamentTeam.tournamentId)
            );
            return this._tournamentTeamsSubject.next(
              addItemInArray(this._tournamentTeamsSubject.value, tournamentTeam)
            );
          }

          case 'TOURNAMENT_TEAM_UPDATED': {
            return this._tournamentTeamsSubject.next(
              updateItemInArray(this._tournamentTeamsSubject.value, TournamentTeam.toFront(message['data']))
            );
          }

          case 'TOURNAMENT_TEAM_DELETED': {
            return this._tournamentTeamsSubject.next(
              deleteItemFromArray(this._tournamentTeamsSubject.value, TournamentTeam.toFront(message['data']))
            );
          }
        }
      });
  }
}
