import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { loadScript } from '@shared/util/scripts';

@Injectable()
export class RecaptchaService {
  private _loadedSubject;

  load() {
    if (!this._loadedSubject) {
      this._loadedSubject = new ReplaySubject<boolean>(1);
      window['recaptchaLoaded'] = () => {
        this._loadedSubject.next(true);
      };
      loadScript('https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit');
    }

    return this._loadedSubject;
  }
}
