import { Component, Input } from '@angular/core';
import * as RoutingMap from '@app/routing-map';
import { TeamEvent } from '@core/models/team-event';
import { TeamEventTypeLocalization } from '@core/localization/team-event-type';
import { Team } from '@core/models/team';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '@ui-kit/toast/toast.service';

@Component({
  selector: 'mtg-team-event-card',
  templateUrl: './team-event-card.component.html',
  styleUrls: ['./team-event-card.component.scss']
})
export class TeamEventCardComponent {
  @Input()
  teamEvent: TeamEvent;
  @Input()
  canManage: boolean;
  @Input()
  team: Team;
  teamEventTypeLocalization = TeamEventTypeLocalization;

  constructor(private httpClient: HttpClient,
              private toastsService: ToastService) {}

  teamEventDetailRoute(teamEventId: number): string[] {
    return [RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE, this.team.id.toString(), RoutingMap.ROUTE_TEAM_EVENT_ROOT, teamEventId.toString()];
  }

  editTeamEventRoute(teamEventId: number): string[] {
    return [RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE, this.team.id.toString(), RoutingMap.ROUTE_TEAM_EVENT_ROOT, teamEventId.toString(),
      RoutingMap.ROUTE_TEAM_EVENT_EDIT];
  }

 downloadCalendarFile() {
    return this.httpClient
      .get(`/api/v1/team_event/${this.teamEvent.id}/event.ics`, { responseType: 'blob' })
      .subscribe(response => {
          const fileUrl = URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = fileUrl;
          a.download = `event.ics`;
          a.click();
        }, error => {
          this.toastsService.danger('Возникла проблема, мы уже её решаем. Попробуйте позднее');
        });
  }
}
