import { Component, OnInit } from '@angular/core';
import { DialogService } from '@ui-kit/dialog/dialog.service';
import { AuthService } from '@core/services/auth.service';
import { take } from 'rxjs/operators';
import { EntryWizardComponent } from '@app/shared/components/entry-wizard/entry-wizard.component';
import { UserService } from '@core/services/user.service';
import { changeFavicons } from '@shared/util/favicon';
import * as RoutingMap from '@app/routing-map';

@Component({
  selector: 'mtg-app-layout',
  templateUrl: './app-layout.component.html',
})
export class AppLayoutComponent implements OnInit {
  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    const wizardName = 'entry';
    this.authService.user$.pipe(take(1)).subscribe(user => {
      if (!user.wizards || user.wizards.indexOf(wizardName) < 0) {
        setTimeout(() => {
          this.showWizard(wizardName);
        }, 0);
      }
    });
  }

  showWizard(wizardName: string) {
    const dialogRef = this.dialogService.open(EntryWizardComponent, {
      ignoreBackdropClick: true,
      closeOnEsc: false,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.userService.setWizardShowed(wizardName).subscribe();
    });
  }
}
