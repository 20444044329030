import { ErrorHandler, NgModule, Injectable } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { LayoutModule as CdkLayoutModule } from '@angular/cdk/layout';
import { MetrikaModule } from 'ng-yandex-metrika';
import { environment } from '@env/environment';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/modules/shared/shared.module';
import { LayoutModule } from '@app/layout/layout.module';
import { SentryErrorHandler } from '@app/sentry-error-handler.service';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { NotificationTemplateService } from '@app/notification-template.service';
import {
  NotificationTemplateService as BaseNotificationTemplateService
} from '@shared/modules/notification/services/notification-template.service';
import { NotificationModule } from '@shared/modules/notification/notification.module';
import { UiKitModule } from '@ui-kit/ui-kit.module';
import { VkComponent } from '@app/vk.component';
// import { PwaPromptModule } from '@shared/modules/pwa-prompt/pwa-prompt.module';

registerLocaleData(localeRu);

@Injectable()
export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
    'swipe': {velocity: 0.4, threshold: 20}
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES),
    CoreModule,
    LayoutModule,
    SharedModule,
    CdkLayoutModule,
    NotificationModule,
    MetrikaModule.forRoot(
      {id: environment.metrikaCounter, webvisor: false},
    ),
    UiKitModule,
    // PwaPromptModule,
  ],
  declarations: [
    AppComponent,
    VkComponent,
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    NotificationTemplateService,
    {provide: BaseNotificationTemplateService, useExisting: NotificationTemplateService},
    {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    NotificationTemplateService,
    {provide: BaseNotificationTemplateService, useExisting: NotificationTemplateService},
  ]
})
export class AppModule {}
