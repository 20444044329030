import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, listField } from './util';
import { Organization } from './organization';
import { File } from './file';
import { Sport } from '@core/models/sport';

@ModelInstance({
  mappingFields: {
    id: 'id',
    logo: 'logo',
    contact_email: 'contactEmail',
    contact_phone: 'contactPhone',
    name: 'name',
    alias: 'alias',
    domain: 'domain',
    sport: 'sport',
    description: 'description',
    social_links: 'socialLinks',
    links: 'links',
    organization: 'organization',
    tournaments_count: 'tournamentsCount',
    closest_game_datetime: 'closestGameDatetime',
    cover: 'cover',
    cover_mobile: 'coverMobile',
    about: 'about',
  },
  relation: {
    organization: Organization,
    logo: File,
    cover: File,
    coverMobile: File,
    sport: Sport,
  }
})
export class League extends BaseModel {
  id: number;
  logo: File;
  contactEmail: string;
  contactPhone: string;
  name: string;
  alias: string;
  domain: string;
  sport: Sport;
  description: string;
  socialLinks: string[];
  links: string[];
  organization: Organization;
  tournamentsCount: number;
  closestGameDatetime: string;
  cover: File;
  coverMobile: File;
  about: string;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}

  getEmptyLogoClass(): string {
    const classList = ['empty-logo'];
    if (this.sport.isBasketball()) {
      classList.push('empty-logo-basketball');
    }
    if (this.sport.isVolleyball()) {
      classList.push('empty-logo-volleyball');
    }
    if (this.sport.isHockey()) {
      classList.push('empty-logo-hockey');
    }
    if (this.sport.isFootball()) {
      classList.push('empty-logo-football');
    }
    if (this.sport.isWaterpolo()) {
      classList.push('empty-logo-waterpolo');
    }
    return classList.join(' ');
  }

  get vkLink(): string {
    return this.socialLinks.find(link => link.substr(0, 15) === 'https://vk.com/');
  }

  get fbLink(): string {
    return this.socialLinks.find(link => link.substr(0, 25) === 'https://www.facebook.com/');
  }

  get instaLink(): string {
    return this.socialLinks.find(link => link.substr(0, 25) === 'https://www.instagram.com');
  }

  getRuDomain(): boolean {
    const domainSplit = this.domain.split('.');
    return domainSplit[(domainSplit.length - 1)] === 'ru';
  }
}
