<label class="handle" (click)="toggleOptions()">
  <div class="label" *ngIf="placeholder">{{ placeholder }} {{ required ? '*' : ''}}</div>
  <div class="value">{{ formattedValue }}</div>
  <div class="search" *ngIf="withSearch">
    <input [value]="search" (input)="searchValue($event.target.value)" placeholder="Поиск" />
  </div>
</label>
<div class="options">
  <ng-content></ng-content>
</div>
<div class="error-text" *ngIf="control && control.dirty && !control.valid">
  <div *ngFor="let error of getErrors()">{{ error }}</div>
</div>
