import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localization',
})
export class LocalizationPipe implements PipeTransform {
  transform(value: any, dictionary: {[key: string]: string}): any {
    if (dictionary && dictionary[value]) {
      return dictionary[value];
    }
    return value;
  }
}
