import { Component, Input, OnInit } from '@angular/core';
import { Poll, PollStatuses } from '@core/models/poll';
import * as RoutingMap from '@app/routing-map';
import { Team } from '@core/models/team';
import { TeamAccess } from '@core/models/team-access';

@Component({
  selector: 'mtg-poll-card',
  templateUrl: './poll-card.component.html',
  styleUrls: ['./poll-card.component.scss'],
})
export class PollCardComponent {
  private _poll: Poll;
  @Input()
  team: Team;
  @Input()
  set poll(poll: Poll) {
    this._poll = poll;
    if (this._poll.variantsWithAnswers) {
      this.maxPercent = Math.max(...this.poll.variantsWithAnswers.map(item => item.percent));
    }
  }
  get poll(): Poll {
    return this._poll;
  }
  @Input()
  access: TeamAccess;
  maxPercent: number;
  pollStatuses = PollStatuses;

  isDraw(): boolean {
    return this.poll.variantsWithAnswers.length > 1 &&
      this.poll.variantsWithAnswers[0].percent === this.poll.variantsWithAnswers[1].percent;
  }

  getPollDetailRoute(pollId: number): string[] {
    return [
      RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE,
      this.team.id.toString(),
      RoutingMap.ROUTE_TEAM_POLL_ROOT,
      pollId.toString()
    ];
  }

  getPollEditRoute(pollId: number): string[] {
    return [
      RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE,
      this.team.id.toString(),
      RoutingMap.ROUTE_TEAM_POLL_ROOT,
      pollId.toString(),
      RoutingMap.ROUTE_TEAM_POLL_EDIT
    ];
  }
}
