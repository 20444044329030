<div class="file-uploader" (mtgFileDropZone)="addFile($event)">
  <div class="text text-muted">
    <span class="icon-load"></span>
    {{ title }}
  </div>
  <div class="files" *ngIf="value.length > 0 && type === 'file'">
    <div class="file" *ngFor="let file of value">
      <span class="icon-doc"></span>
      {{ file.name }}
      <button class="btn btn-circle muted" (click)="removeFile(file)"><span class="icon-close"></span></button>
    </div>
  </div>
  <div class="files" *ngIf="value.length > 0 && type === 'image'">
    <div class="image" *ngFor="let file of value">
      <img [mtgImageReader]="file">
      <div class="filename">
        <button class="btn btn-circle" (click)="removeFile(file)"><span class="icon-close"></span></button>
        {{ file.name }}
      </div>
    </div>
  </div>
  <div class="text">
    Перетащите файлы в эту область или<br />
    <label class="file-input">
      загрузите с устройства
      <input type="file" (change)="inputChanged($event)" multiple>
    </label>
  </div>
</div>
