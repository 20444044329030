<div class="game-item"
     [routerLink]="gameDetailRoute(game.id)"
>
  <div class="headline">
    <div class="game-sport-type" >
        <ng-container *ngIf="isProfile">
        <span class="icon icon-volleyball" *ngIf="game.team.sport.isVolleyball()"></span>
        <span class="icon icon-basketball" *ngIf="game.team.sport.isClassicBasketball()"></span>
        <span class="icon icon-3x3" *ngIf="game.team.sport.isStreetball()"></span>
        <span class="icon icon-hockey-puck" *ngIf="game.team.sport.isHockey()"></span>
        <span class="icon icon-football" *ngIf="game.team.sport.isClassicFootball()"></span>
        <span class="icon icon-football-mini" *ngIf="game.team.sport.isMiniFootball()"></span>
        <span class="icon icon-handball" *ngIf="game.team.sport.isHandball()"></span>
        <span class="icon icon-waterpolo" *ngIf="game.team.sport.isWaterpolo()"></span>
        <span class="text">{{ game.team.sport.name }}</span>
        </ng-container>
    </div>
    <div class="tag primary" *ngIf="game.status !== gameStatuses.closed">
      <ng-container *ngIf="game.playoffStage">{{ game.playoffStage }}</ng-container>
      <ng-container *ngIf="game.tournamentTour && !game.playoffStage">{{ game.tournamentTour }} тур</ng-container>
      <ng-container *ngIf="!game.tournamentTour && !game.playoffStage">Матч</ng-container>
    </div>
    <div class="tag" *ngIf="game.status === gameStatuses.closed">
      <ng-container *ngIf="game.playoffStage">{{ game.playoffStage }}</ng-container>
      <ng-container *ngIf="game.tournamentTour && !game.playoffStage">{{ game.tournamentTour }} тур</ng-container>
      <ng-container *ngIf="!game.tournamentTour && !game.playoffStage">Матч завершен</ng-container>
    </div>
    <div class="tag" *ngIf="game.status === gameStatuses.archived">Матч завершен</div>
  </div>
  <div class="game-details">
    <div class="team-meta">
      <div class="photo">
        <img [src]="game.team.logo.path" *ngIf="game.team.logo.path">
        <div [class]="game.team.getEmptyLogoClass()" *ngIf="!game.team.logo.path"></div>
        <div class="overlay">{{ game.teamScore !== null ? +game.teamScore : '&mdash;' }}</div>
      </div>
      <div>{{ game.team.name }}</div>
    </div>
    <div class="score">:</div>
    <div class="team-meta" *ngIf="game.competitorTeam.id">
      <div class="photo">
        <img [src]="game.competitorTeam.logo.path" *ngIf="game.competitorTeam.logo.path">
        <div [class]="game.competitorTeam.getEmptyLogoClass()" *ngIf="!game.competitorTeam.logo.path"></div>
        <div class="overlay">{{ game.competitorTeamScore !== null ? +game.competitorTeamScore : '&mdash;' }}</div>
      </div>
      <div>{{ game.competitorTeam.name }}</div>
    </div>
    <div class="team-meta" *ngIf="!game.competitorTeam.id">
      <div class="photo">
        <div [class]="game.team.getEmptyLogoClass()"></div>
        <div class="overlay">{{ game.competitorTeamScore !== null ? +game.competitorTeamScore : '&mdash;' }}</div>
      </div>
      <div>{{ game.competitorTeamName }}</div>
    </div>
  </div>
  <div class="controls">

    <div class="location">
      <div class="datetime" *ngIf="game.datetime">
        <a *ngIf="!game.isClosed" (click)="downloadCalendarFile()" class="link" target="_blank" mtgStopPropagation>
          <span class="icon-flag"></span>
          <span class="date">
            {{game.datetime | date: 'd MMMM, yyyy'}}
          </span>
          <span class="time">
            {{game.datetime | date: 'HH:mm'}}
          </span>
        </a>
        <ng-container *ngIf="game.isClosed">
          <span class="date">
            {{game.datetime | date: 'd MMMM, yyyy'}}
          </span>
            <span class="time">
            {{game.datetime | date: 'HH:mm'}}
          </span>
        </ng-container>
      </div>
      <div class="datetime" *ngIf="!game.datetime">
        <span class="icon-flag"></span>
        Дата матча не назначена
      </div>
      <div class="place">{{ game.location || 'Площадка будет объявлена позже' }}</div>
    </div>
    <a class="btn btn-circle muted pull-right"
       mtgStopPropagation
       [routerLink]="editGameRoute(game.id)"
       *ngIf="canManageGames && game.status !== gameStatuses.archived && game.status !== gameStatuses.closed"
    >
      <i class="icon-edit"></i>
    </a>
  </div>
</div>
