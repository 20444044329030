import { BaseModel, enumField, ModelInstance, ToBackHook, ToFrontHook } from './util';
import { User } from './user';
import { Team } from './team';

export enum TeamUserRole {
  member = 1,
  moderator,
  admin,
  coach,
  head_coach,
  playing_coach,
  manager,
  head_manager,
  captain,
  assistant,
  president,
  vice_president,
  press_attache,
  executive,
  senior_coach,
  assistant_coach,
  serviceman,
  game_application_manager,
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    team_id: 'teamId',
    user: 'user',
    role: 'role',
    number: 'number',
    team: 'team',
  },
  relation: {
    user: User,
    team: Team,
    role: enumField(TeamUserRole)
  }
})
export class TeamUser extends BaseModel {
  id: number;
  teamId: number;
  user: User;
  team: Team;
  number: number;
  role: TeamUserRole;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(teamPlayer: TeamUser): any {}
}
