import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, listField } from './util';
import { Team } from './team';
import { TournamentGroup } from './tournament-group';
import { Game } from '@core/models/game';
import { Tournament } from '@core/models/tournament';
import { File } from '@core/models/file';
import { TournamentTeamFieldValue } from '@core/models/tournament-team-field-value';

@ModelInstance({
  mappingFields: {
    nickname: 'nickname',
    color: 'color',
  }
})
export class TournamentTeamAdditionalData {
  nickname: string;
  color: string;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    team_id: 'teamId',
    tournament_id: 'tournamentId',
    tournament: 'tournament',
    team: 'team',
    name: 'name',
    logo: 'logo',
    team_photo: 'teamPhoto',
    team_uniform: 'teamUniform',
    group: 'group',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    last_games_count: 'lastGamesCount',
    last_games_won: 'lastGamesWon',
    score_sum: 'scoreSum',
    missed_sum: 'missedSum',
    score_points_sum: 'scorePointsSum',
    missed_points_sum: 'missedPointsSum',
    points: 'points',
    mt_points: 'mtPoints',
    users_count: 'usersCount',
    games: 'games',
    notifications_count: 'notificationsCount',
    has_changes: 'hasChanges',
    draw_games_count: 'drawGamesCount',
    lose_games_count: 'loseGamesCount',
    win_overtime_games_count: 'winOvertimeGamesCount',
    lose_overtime_games_count: 'loseOvertimeGamesCount',
    game_score_0_2: 'gameScore_0_2',
    game_score_0_3: 'gameScore_0_3',
    game_score_1_2: 'gameScore_1_2',
    game_score_2_0: 'gameScore_2_0',
    game_score_3_0: 'gameScore_3_0',
    game_score_2_1: 'gameScore_2_1',
    game_score_2_3: 'gameScore_2_3',
    game_score_3_2: 'gameScore_3_2',
    additional_data: 'additionalData',
    field_values: 'fieldValues',
  },
  relation: {
    tournament: Tournament,
    team: Team,
    group: TournamentGroup,
    games: Game,
    logo: File,
    teamPhoto: File,
    teamUniform: File,
    additionalData: TournamentTeamAdditionalData,
    fieldValues: listField(TournamentTeamFieldValue),
  }
})
export class TournamentTeam extends BaseModel {
  id: number;
  teamId: number;
  tournamentId: number;
  tournament: Tournament;
  team: Team;
  name: string;
  logo: File;
  teamPhoto: File;
  teamUniform: File;
  group: TournamentGroup;
  gamesCount: number;
  wonGamesCount: number;
  lastGamesCount: number;
  lastGamesWon: number;
  scoreSum: number;
  missedSum: number;
  scorePointsSum: number;
  missedPointsSum: number;
  points: number;
  mtPoints: number;
  usersCount: number;
  games: Game[];
  notificationsCount: number;
  hasChanges: boolean;
  drawGamesCount: number;
  loseGamesCount: number;
  winOvertimeGamesCount: number;
  loseOvertimeGamesCount: number;
  gameScore_0_2?: number;
  gameScore_0_3?: number;
  gameScore_1_2?: number;
  gameScore_2_0?: number;
  gameScore_2_1?: number;
  gameScore_2_3?: number;
  gameScore_3_0?: number;
  gameScore_3_2?: number;
  additionalData: TournamentTeamAdditionalData;
  fieldValues: TournamentTeamFieldValue[];

  get gamesWonPercent(): number {
    if (!this.gamesCount) {
      return 0;
    }
    return Math.floor(1000 * this.wonGamesCount / this.gamesCount) / 10;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
