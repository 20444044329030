<div class="logo">
  <img [src]="notification.logo" *ngIf="notification.logo">
  <div [class]="notification.emptyLogoClass"  *ngIf="!notification.logo && notification.emptyLogoClass"></div>
</div>
<div class="body">
  <div class="date">
    <span
      mtgStopPropagation
      (click)="$event.preventDefault();viewed.emit()"
      class="new"
      *ngIf="!notification.model.viewed && !notification.model.protected"
      mtgTooltip="Отметить прочитанным"
    ></span>
    <span
      class="new"
      *ngIf="!notification.model.viewed && notification.model.protected"
    ></span>
    {{ notification.model.createdAt | mtgDate }}
  </div>
  <div class="title">{{ notification.title }}</div>
  <div class="description">
    {{ notification.description }}
  </div>
  <div class="actions" *ngIf="notification.actions && !notification.model.viewed">
    <button
      *ngFor="let action of notification.actions"
      [mtgButton]="action.buttonColor"
      [mtgButtonType]="action.buttonType"
      [loading]="loading === action"
      (click)="$event.preventDefault();doAction(action)"
      mtgStopPropagation
      [size]="action.buttonType === 'link' ? 'md' : 'sm'"
    >{{ action.text }}</button>
  </div>
</div>
