import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from '@core/models/user';
import { IFacebookLogin, IRsvLogin, IVKLogin } from '@shared/modules/authorization/models/social-auth';
import { RegistrationModel } from '@shared/modules/authorization/models/registration.model';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { ChangePasswordModel } from '@shared/modules/authorization/models/change-password.model';
import { TeamInviteExternal } from '@core/models/team-invite-external';
import { InviteConfirmModel } from '@shared/modules/authorization/models/invite-confirm.model';

@Injectable()
export class AuthService {
  private _user$: ReplaySubject<User>;

  constructor(
    private httpClient: HttpClient
  ) {}

  get user$() {
    if (!this._user$) {
      this._user$ = new ReplaySubject<User>(1);
      this.currentUser()
        .subscribe(user => {
          this._user$.next(user);
        }, error => {
          this._user$.next(null);
        });
    }
    return this._user$;
  }

  currentUser(): Observable<User> {
    return this.httpClient
      .get('/api/v1/user/current')
      .pipe(
        map(response => User.toFront(response))
      );
  }

  login(email: string, password: string): Observable<User> {
    const payload = {
      email,
      password
    };
    return this.httpClient
      .post('/api/v1/auth/login/', payload)
      .pipe(
        map(response => User.toFront(response)),
        tap(user => this._user$.next(user))
      );
  }

  registration(data: RegistrationModel): Observable<User> {
    return this.httpClient
      .post('/api/v1/auth/registration/', RegistrationModel.toBack(data))
      .pipe(
        map(response => User.toFront(response)),
      );
  }

  confirmRegistration(uid: string, token: string): Observable<User> {
    return this.httpClient
      .post('/api/v1/auth/confirmation/', {uid, token})
      .pipe(
        map(response => User.toFront(response)),
        tap(user => this._user$.next(user))
      );
  }

  logout(): Observable<any> {
    return this.httpClient
      .get('/api/v1/auth/logout/')
      .pipe(
        tap(() => this._user$.next(null))
      );
  }

  facebookLogin(payload: IFacebookLogin): Observable<User> {
    return this.httpClient
      .get(`/api/v1/auth/facebook/?code=${payload.code}&redirect_uri=${payload.redirectUri}`)
      .pipe(
        map(response => User.toFront(response)),
        tap(user => this._user$.next(user))
      );
  }

  vkLogin(payload: IVKLogin): Observable<User> {
    return this.httpClient
      .get(`/api/v1/auth/vk/?code=${payload.code}&redirect_uri=${payload.redirectUri}`)
      .pipe(
        map(response => User.toFront(response)),
        tap(user => this._user$.next(user))
      );
  }

  rsvLogin(payload: IRsvLogin): Observable<User> {
    return this.httpClient
      .post(
        '/api/v1/auth/rsv/',
        {
          code: payload.code,
          redirect_uri: payload.redirectUri,
        }
      ).pipe(
        map(data => User.toFront(data)),
        tap(user => this._user$.next(user)),
      );
  }

  resetPassword(email: string, service: string): Observable<any> {
    return this.httpClient
      .post('/api/v1/auth/reset_password/', {email, service});
  }

  changePassword(data: ChangePasswordModel): Observable<any> {
    return this.httpClient
      .post('/api/v1/auth/change_password/', ChangePasswordModel.toBack(data));
  }

  getInviteFormData(uid: string, token: string): Observable<TeamInviteExternal> {
    const params = (new HttpParams()).set('uid', uid).set('token', token);
    return this.httpClient
      .get(`/api/v1/auth/invite/`, {params})
      .pipe(
        map(data => TeamInviteExternal.toFront(data))
      );
  }

  confirmInvite(data: InviteConfirmModel): Observable<User> {
    return this.httpClient
      .post(`/api/v1/auth/invite/`, InviteConfirmModel.toBack(data))
      .pipe(
        map(result => User.toFront(result)),
        tap(user => this.user$.next(user)),
      );
  }

  getTeamRegisterUserData(uid: string, token: string): Observable<User> {
    const params = new HttpParams().set('uid', uid).set('token', token);
    return this.httpClient
      .get(`/api/v1/auth/register_by_team/`, {params})
      .pipe(
        map(data => User.toFront(data))
      );
  }

  confirmTeamRegisterUser(data: InviteConfirmModel): Observable<User> {
    return this.httpClient
      .post('/api/v1/auth/register_by_team/', InviteConfirmModel.toBack(data))
      .pipe(
        map(result => User.toFront(result)),
        tap(user => this.user$.next(user))
      );
  }

  checkEmail(email: string, selfCheck: boolean): Observable<boolean> {
    const params = new HttpParams()
      .set('email', email)
      .set('self_check', selfCheck ? '1' : '0');
    return this.httpClient
      .get('/api/v1/auth/check_email/', {params})
      .pipe(map(data => data['valid']));
  }
}
